import { authHeader } from '../_helpers';
import socketIOClient from "socket.io-client";

const  URL = "https://d2sapi.opentlv.com/monitoring";

const ENDPOINT = "https://d2sapi.opentlv.com";


export const monitoringService = {
    getPlayers,
    searchPlayers,
    getInfosPlayer,
    logout

};


function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function getPlayers(page ,sizePerPage) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${URL}/players/?page=`+page + '&sizePerPage='+ sizePerPage, requestOptions)
    .then(handleResponse)
    .then(players => {
    
        return players;
    });
}

function searchPlayers(term,page ,sizePerPage) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${URL}/players/?q=` + `&page=`+page + '&sizePerPage='+ sizePerPage, requestOptions)
    .then(handleResponse)
    .then(players => {
    
        return players;
    });
}

function getInfosPlayer(id){ 
    const requestOptions = {

    method: 'POST',
    headers: authHeader("application/json"),
    //headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({id})
};
console.log("infos");

console.log(id);

return fetch(`${URL}/update-timestamp`, requestOptions)
    .then(handleResponse)    
    .then(playlistID=> { 

        const socket = socketIOClient(ENDPOINT);
        socket.on("connect", data => {
            socket.emit('subscribe-to-channel', 'player'+ playlistID);
            socket.emit('reloadMedialist',playlistID);

        });
      });

}
function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}



