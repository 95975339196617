import { interfaceConstants } from '../_constants';

const initialState = {
  showMyComponent: true
};

export function campagne(state = initialState, action) {
  switch (action.type) {
    case interfaceConstants.CAMPAGNE_EDIT:
      console.log('showEditForm');
      return {...state, showMyComponent: action.payload};
   
    default:
      return state
  }
}