import React, {Component, Fragment} from 'react';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import PageTitleAlt3 from '../../Layout/AppMain/PageTitleAlt3';
import cx from 'classnames';



import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActions,interfaceActions } from '../../_actions';

import {
    Row, Col,
    Alert,
    Button,
    CardHeader,
    Table,
    ButtonGroup,
    Nav,
    NavItem,
    NavLink,
    Popover,
    PopoverBody,
    Progress,
    CardTitle,
    Card,
    CardBody,
    DropdownItem, DropdownToggle, DropdownMenu,
    UncontrolledButtonDropdown, CardFooter,
    Container
} from 'reactstrap';

import Box from './Box';
import PageTitle from '../../Layout/AppMain/PageTitle';
import Client from './Client';

import {
    faAngleUp,
    faAngleDown,
    faQuestionCircle,
    faBusinessTime,
    faCog
} from '@fortawesome/free-solid-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

class Interface extends Component {
  
    constructor(props) {
        super(props);

        this.togglePop1 = this.togglePop1.bind(this);

        this.state = {
            visible: true,
            popoverOpen1: false,
            seriesRadial: [76],

            
        }
        this.onDismiss = this.onDismiss.bind(this);
    }
    componentDidMount() {
      console.log('MOUNBT');
      this.props.getClients();

//      this.props.getEnseignes();
  }
    togglePop1() {
        this.setState({
            popoverOpen1: !this.state.popoverOpen1
        });
    }

    onDismiss() {
        this.setState({visible: false});
    }

    render() {
        const { enseigne, enseignes, clients} = this.props;
        const breads = [{ name: 'interface',href:'' },{ name: 'enseigne',href:'interface' }];

        return (
            
            <Fragment>
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                           <div>
                    <div
                        className={cx("app-inner-layout", {
                            'open-mobile-menu': this.state.active,
                        })}
                            
                    >
                      <div className="app-inner-layout__header bg-heavy-rain">
                            <PageTitle
                                heading="Interface" 
                                subheading="gestion de vos enseignes"
                                icon="pe-7s-power icon-gradient bg-mixed-hopes"
                                breads={breads}
                            />
                        </div>
                        <div class="app-inner-layout__wrapper">
                        <Card className="app-inner-layout__content">

                    <Container fluid>
                               <Row>
                     
                            
                               {clients.items &&
                                <>
                                {clients.items.map((client, index) =>

                                    <Client key={index} client={client} />
                                )}
                                </>
                                }           
                                {/** 
                                {enseignes.items &&
                                <>
                                {enseignes.items.map((enseigne, index) =>

                                    <Box key={index} enseigne={enseigne} />
                                )}
                                </>
                                }           
                             **/}
                        </Row>
                    
                    </Container>
                    </Card>
                    </div>
                    </div>

                    </div>

                </ReactCSSTransitionGroup>
            </Fragment>
        )
    }

_onAction(e) {
  console.log('user did something', e)
}

_onActive(e) {
  console.log('user is active', e)
  console.log('time remaining', this.idleTimer.getRemainingTime())
}

_onIdle(e) {
  console.log('user is idle', e)
  console.log('last active', this.idleTimer.getLastActiveTime())
}
}


function mapState(state) {
const { users, authentication, enseignes,clients } = state;
const { user } = authentication;
return { user, users, enseignes,clients };
}

const actionCreators = {
getEnseignes: interfaceActions.getEnseignes,
getClients: interfaceActions.getClients,

}

//const connectedInterfacePage = connect(mapState, actionCreators)(Interface);
//export default connect(mapState, actionCreators)(Interface);
const connectedInterface = connect(mapState, actionCreators)(Interface);
export { connectedInterface as Interface };