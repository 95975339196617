import { combineReducers } from 'redux';

import { authentication } from './authentication.reducer';
import { registration } from './registration.reducer';
import { campagne } from './campagne.reducer';
import { campagnes } from './campagnes.reducer';
import { secteurs } from './secteurs.reducer';
import { users } from './users.reducer';
import { medias } from './medias.reducer';
import { alert } from './alert.reducer';
import { enseignes } from './enseignes.reducer';
import { enseigne } from './enseigne.reducer';
import { pdvs } from './pdvs.reducer';
import { pdvclient } from './pdvclient.reducer';

import { assigned } from './assigned.reducer';
import { eventsPlaylist } from './eventsPlaylist.reducer';
import { players } from './players.reducer';
import { clients } from './clients.reducer';
import { canaux } from './canaux.reducer';

import  ThemeOptions  from './ThemeOptions/ThemeOptions';
import { reducer as formReducer } from 'redux-form'

const rootReducer = combineReducers({
  authentication,
  registration,
  users,
  enseignes,
  enseigne,
  campagne,
  campagnes,
  secteurs,
  pdvs,
  alert,
  medias,
  assigned,
  eventsPlaylist,
  ThemeOptions,
  players,
  clients,
  canaux,
  pdvclient,
  form: formReducer

});

export default rootReducer;
  