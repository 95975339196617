import React, {Component, Fragment} from 'react';
import Chart from 'react-apexcharts'
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import {Progress} from 'react-sweet-progress';
import CountUp from 'react-countup';
import DateTimePicker from 'react-datetime-picker';
import TimePicker from 'react-time-picker';
import Dropzone from 'react-dropzone'
import {AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio} from 'availity-reactstrap-validation';
import { store } from 'react-notifications-component';

import {
    Col, Row, Button,
    Card, Nav, NavLink, NavItem,
    DropdownMenu, DropdownItem,ListGroup, ListGroupItem,
    CardBody,CustomInput, InputGroup, InputGroupAddon, Input,
    Table, UncontrolledButtonDropdown, DropdownToggle,CardTitle,
    ButtonGroup,Form, FormGroup, Label,FormText, Container
} from 'reactstrap';

import Hamburger from 'react-hamburgers';
import cx from 'classnames';
import { connect } from 'react-redux';

import { interfaceActions } from '../../../_actions';
import { Support } from '.';

export default class FormMedia extends Component {

    constructor(props) {
        super(props);
        

        this.state = {
            submitted: false,
            name : "",
            datefrom: new Date(),
            dateto: new Date(),
            timefrom: new Date(),
            timeto: new Date(),
            pdvs :[],
            selectOptions: [],
            selectOptionsIds: [],
            selectLanguage: [],
            selectLanguageIds: [],
            selectWebview:false,
            files: [],
            languages : [
                { id: "FR", name: "Français", value: "Français" },
                { id:"ES", name: "Espagnol", value: "Espagnol" },
                { id: "GB", name: "Anglais", value: "Anglais" },
                { id: "IT", name: "Italien", value: "Italien" },
                { id: "DE", name: "Allemand", value: "Allemand" },
                { id: "RO", name: "Roumain", value: "Roumain" }
              ],


        };
        
          
        this.handleChangeForm = this.handleChangeForm.bind(this);
        this.handleChangeLanguage = this.handleChangeLanguage.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);
        this.onDrop = this.onDrop.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.resetForm = this.resetForm.bind(this);

    }
    resetForm = () => {
        this.setState({ username: '' })
        //close form

      }
    componentDidMount(){

    
    }
        
    handleChange(event) {
        
            let target = event.target
            let name = target.name
            //    let value = Array.from(target.selectedOptions, option => option.value);
             //   this.setState({
               // [name]: value
                //});
                console.log(event.target.selectedOptions);
                console.log(event.target);

                let value = Array.from(event.target.selectedOptions, option => option.value );
                let id = Array.from(event.target.selectedOptions, option => option.id );
                this.setState({selectOptions: value});
                this.setState({selectOptionsIds: id});
    
          
      }
      handleChangeLanguage(event) {
        
        let target = event.target
        let name = target.name
        //    let value = Array.from(target.selectedOptions, option => option.value);
         //   this.setState({
           // [name]: value
            //});
            console.log(event.target.language);
            console.log(event.target.id);
            
            console.log("event", event.target, event.target.selectedIndex);
            //this.setState({
              //  value: event.target.value,
                //id: event.target[event.target.selectedIndex].id
              //});
            this.setState({selectLanguage: event.target.value});
            this.setState({selectLanguageIds:event.target[event.target.selectedIndex].id});

            //let value = Array.from(event.target.selectLanguage, option => option.value );
            //let id = Array.from(event.target.selectLanguage, option => option.id );
            //this.setState({selectLanguage: value});
            //this.setState({selectLanguageIds: id});



      
  }

      handleChangeForm(event) {
        const target = event.target;
        const value = target.name === 'webview' ? target.checked : target.value;
        const name = "selectWebview";
    
        this.setState({
          [name]: value
        });
    
        //const { name, value } = e.target;
     //   this.setState({ [name]: value });
    }
    onDrop(files) {
        this.setState({files});
    }

    onCancel() {
        this.setState({
            files: []
        });
    }
      handleSubmit(event) {
         event.preventDefault();

        this.setState({ submitted: true });
        
        const { media ,files, selectOptionsIds, selectLanguageIds, selectWebview} = this.state; 
        //if (files.length == 0)
       if (files.length == 0){
        store.addNotification({
            title: "Oops une erreur est survenue !",
            message: "media obligatoire !!",
            type: "danger",
            insert: "top",
            container: "top-center",
            animationIn: ["animated", "fadeIn"],
            animationOut: ["animated", "fadeOut"],
            dismiss: {
              duration: 10000,
              onScreen: true
            }
          });

       }else{

        this.props.formMedia(files,this.props.enseigne_id,selectOptionsIds, selectLanguageIds, selectWebview);
    }

        this.resetForm();
        //alert('Your favorite flavor is: ' + this.state.value);
       // event.preventDefault();
      }

    render() {
        const files = this.state.files.map(file => (
            <ListGroupItem key={file.name}>
                {file.name} - {file.size} bytes
            </ListGroupItem>
        ))
        return (
            <Fragment>
                <AvForm onValidSubmit={this.handleSubmit} encType="multipart/form-data" onReset={this.resetForm}>

            <Row>

                <Col md="8">
               
                    <FormGroup row>
                        <Label for="exampleSelect" sm={2}>Secteur</Label>
                        <Col sm={10}>
                            <AvField type="select" name="text" id="secteurs" onChange={this.handleChange} value={this.state.selectOptions.value} required> 
                            <option> Veuillez choisir un secteur</option>
                            {this.props.secteurs.items && 
                                    this.props.secteurs.items.map((item) => <option id={item.id} key={item.id}>{item.name}</option>)}
            
                            </AvField>
                        </Col>
                    </FormGroup>               
                    <FormGroup row>
                        <Label for="language" sm={2}>Langues</Label>
                        <Col sm={10}>
                            <AvField type="select" name="language" id="language" onChange={this.handleChangeLanguage} value={this.state.selectLanguage.value}  required>
                            <option >Veuillez choisir une langue</option>
                            
                            {this.state.languages && 
                                    this.state.languages.map((item) => <option id={item.id} key={item.id}>{item.name}</option>)}
                            </AvField>
                        </Col>
                    </FormGroup>               

                    </Col>
                   
                <Col md="4">
                <AvGroup>
                <Dropzone 
                                onDrop={this.onDrop.bind(this)}
                                accept="video/mp4,application/zip, application/octet-stream, 
                                application/zip, application/octet-stream, application/x-zip-compressed, multipart/x-zip, 
                                image/jpeg,application/pdf,image/png"
                                onFileDialogCancel={this.onCancel.bind(this)}
                                required >
                                {({getRootProps, getInputProps}) => (
                                    <div {...getRootProps()}>
                                        <input {...getInputProps()} />
                                        <div className="dropzone-content">
                                            <p>Déposez votre fichier ou bien cliquez ici.</p>
                                        </div>
                                    </div>
                                )}
                            </Dropzone>
                            </AvGroup>
                    </Col>
                <Col md="12">
                <b className="mb-2 d-block">Fichier déposé </b>
                        <ListGroup>
                            {files}
                        </ListGroup>

                </Col>
            <Col sm={{size: 10, offset: 2}}>
                        <Button className="" >Ajouter le media</Button>
            </Col>
            </Row>

            </AvForm>
            </Fragment>
            
        );
    }
}


function mapState(state) {
    const { users, authentication, enseignes,enseigne } = state;
    const { user } = authentication;
    
    return { user, users,enseignes,enseigne  };
    }
    
    const actionCreators = {
        addMedia: interfaceActions.addMedia,
        titi: interfaceActions.getEnseignes,
    }
    
    const connectedFormMedia = connect(mapState, actionCreators)(FormMedia);
    export { connectedFormMedia as FormMedia };
