import React, {Component, Fragment} from 'react';
import { useDrop } from 'react-dnd'
import ItemTypes from './ItemTypes'
import { connect } from 'react-redux';

import {
    Col, Row, Button,
    Card, Nav, NavLink, NavItem,
    DropdownMenu, DropdownItem,ListGroup, ListGroupItem,CardHeader, 
    CardBody,CustomInput, InputGroup, InputGroupAddon, Input,
    Table, UncontrolledButtonDropdown, DropdownToggle,CardTitle,
    ButtonGroup,Form, FormGroup, Label,FormText, Container,CardFooter,
    Badge
} from 'reactstrap';
import { interfaceActions } from '../../../_actions';
import {alertActions} from '../../../_actions/alert.actions'


class Campagnes extends Component {
  
    constructor(props) {
        super(props);
        this.state = {
            props,
            assigned : []
        };

        this.onDragOver = this.onDragOver.bind(this);
        this.onDropMedia = this.onDropMedia.bind(this);
        const style = {
            height: '12rem',
            width: '12rem',
            marginRight: '1.5rem',
            marginBottom: '1.5rem',
            color: 'white',
            padding: '1rem',
            textAlign: 'center',
            fontSize: '1rem',
            lineHeight: 'normal',
            float: 'left',
          }
        let  backgroundColor = 'red';
    
        if (this.state.targetbox === null) {
            backgroundColor = 'darkkhaki'
        
        }

    }

	
	onDragOver = (event) => {
        //change le bg
	    event.preventDefault();
	}
    onDropMedia = (event, cat) => {
        console.log("ON DROP");
        const test = event.dataTransfer.getData("media");

        console.log(event.dataTransfer.getData("media"));
        console.log(this.props.campagne.id);
        console.log(this.state);

        this.props.alertSuccess("Media affilié.");
    var property="campagne"+ this.props.campagne.id;

    var updates = {};
    console.log("==========");
    console.log(this.state);
   /* if (this.state.assigned[property]){
          alert("ok");
             //updates.push(item);
        let assigned = [...this.state.assigned[property]]
         assigned.push(item)         
        this.setState({
            ...this.state.assigned[property] = [assigned] 
        });
    }else{
        updates = item
        alert("ko");
        
        this.setState({ 
            ...this.state.assigned[property] = [updates] 
         
        });
       // const style = {backgroundColor: 'red'}

        /*let taskName = event.dataTransfer.getData("taskName");

	    let tasks = this.state.tasks.filter((task) => {
	        if (task.taskName == taskName) {
	            task.type = cat;
	        }
	        return task;
	    });

	    this.setState({
	        ...this.state,
	        tasks
        });
      
    }    */
    
}

     componentDidMount() {
    }
     render() {
        const {campagne ,assigned,style,backgroundColor } = this.props;

  return (
    <Card className="main-card pt-4 pl-3 pr-3 pb-3 nav-item campagne droppable"
    onDragOver={(event)=>this.onDragOver(event)} 
    onDrop={(event)=>this.onDropMedia(event)}
    style={{ ...style, backgroundColor }}>
            <CardHeader>
                <i className="header-icon lnr-laptop-phone icon-gradient bg-plum-plate"> </i>
                {campagne.id} {campagne.name}
                <div className="btn-actions-pane-right actions-icon-btn">
                    <Button className="btn-icon btn-icon-only" color="link">
                        <i className="pe-7s-leaf btn-icon-wrapper"/>
                    </Button>
                    <Button className="btn-icon btn-icon-only" color="link">
                        <i className="pe-7s-cloud-download btn-icon-wrapper"/>
                    </Button>
                    <UncontrolledButtonDropdown>
                        <DropdownToggle className="btn-icon btn-icon-only" color="link">
                            <i className="pe-7s-menu btn-icon-wrapper"/>
                        </DropdownToggle>
                        <DropdownMenu className="dropdown-menu-shadow dropdown-menu-hover-link">
                            <DropdownItem header>Actions</DropdownItem>
                            <DropdownItem>
                                <i className="dropdown-icon lnr-file-empty"> </i>
                                <span>Settings</span>
                            </DropdownItem>
                            <DropdownItem>
                                <i className="dropdown-icon lnr-book"> </i>
                                <span>Actions</span>
                            </DropdownItem>
                           
                        </DropdownMenu>
                    </UncontrolledButtonDropdown>
                </div>
            </CardHeader>
            <CardBody>
            {backgroundColor}
            {style}
            <p>
            <b>Du</b>
            <Badge color="info">{campagne.date_debut}</Badge>
            <b>  au </b>
            <Badge color="info">{campagne.date_fin}</Badge>


            <Badge color="warning">Medias affiliés : {this.state.assigned['campagne'+campagne.id] &&  this.state.assigned['campagne'+campagne.id].length() }</Badge>

            </p>

            </CardBody>

        </Card>
            

  );   
 }
}
function mapState(state) {
    const { users, authentication, campagnes, enseignes, enseigne, pdvs, secteurs, medias, assigned,alert,targetbox} = state;
    const { user } = authentication;
    
    return { user, users, campagnes, enseignes, enseigne, pdvs, secteurs, assigned, medias,alert,targetbox };
    }
    
    const actionCreators = {
        alertSuccess : alertActions.success,
        affiliateMedia : interfaceActions.affiliateMedia,
        deleteCampagne : interfaceActions.deleteCampagne

    }
    
    //const connectedInterfacePage = connect(mapState, actionCreators)(Interface);
    //export default connect(mapState, actionCreators)(Interface);
    const connectedCampagnes = connect(mapState, actionCreators)(Campagnes);
    export { connectedCampagnes  as Campagnes };

