import React from 'react'
import { connect } from 'react-redux';
import { userActions,interfaceActions } from '../../../_actions';
import { interfaceConstants } from '../../../_constants';


const FormEditCampagne = ({show, onClick}) => {
  console.log(show);
  console.log(onClick);
  

  return show ? (
    <div>

    </div>
  ) : 'dfsfdsfsdfdsfsq'
};


const mapStateToProps = (state) => ({
  show: state
});


function mapState(state) {

  const { users, authentication, enseignes } = state;
  const { user } = authentication;
  return { user, users, enseignes };
  }
  const mapDispatchToProps = (dispatch) => ({
    onClick: () => dispatch({
      type: interfaceConstants.CAMPAGNE_EDIT, payload: false
    })
  })
;
  const actionCreators = {
  getEnseignes: interfaceActions.getEnseignes,
  }
  
  //const connectedInterfacePage = connect(mapState, actionCreators)(Interface);
  //export default connect(mapState, actionCreators)(Interface);
  const connectedEditForm = connect(mapStateToProps, mapDispatchToProps)(FormEditCampagne);
  export { connectedEditForm as FormEditCampagne };

