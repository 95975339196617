import { interfaceConstants } from '../_constants';

export function clients(state = {}, action) {
  switch (action.type) {
    case interfaceConstants.CLIENTS_REQUEST:
      return {
        loading: true
      };
    case interfaceConstants.CLIENTS_SUCCESS:
      return {
        items: action.clients
      };
    case interfaceConstants.CLIENTS_FAILURE:
      return { 
        error: action.error
      };
    
    default:
      return state
  }
};