import React, {Component, Fragment} from 'react';
import Chart from 'react-apexcharts'
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import {
    Button,
    UncontrolledButtonDropdown,
    DropdownToggle,
    DropdownMenu,
    Nav,
    NavItem,
    NavLink,
    Row, Col,
    Card, CardBody,
    CardTitle, Container,
    ListGroupItem,
    ListGroup,

} from 'reactstrap';


class Box extends Component {

    constructor(props) {
        super(props);

    
    console.log("BOX");
    console.log(props);
        var image = new Image();
        var img = null;
        var url_image = null;
        
        var url_image = "https://d2sapi.opentlv.com/pictos/"+ this.props.enseigne.id + ".png";
        image.src = url_image;
    
        this.state = {
            src: "https://d2sapi.opentlv.com/pictos/" + this.props.enseigne.id + ".png",
            errored: false,
          };
    
       



    }
    onError = () => {
          this.setState({
                src: "https://d2sapi.opentlv.com/pictos/default.png",
          });
      }
    render() {
        const { img
        } = this.props;
        return (

            <Col md="6" lg="4">

<Card className="card-shadow-primary mb-3">
  <div className="dropdown-menu-header">
    <div className="dropdown-menu-header-inner bg-focus">
        <div className="menu-header-image opacity-3"
                
        />
        <div className="menu-header-content btn-pane-right">
            <div className="avatar-icon-wrapper mr-2 avatar-icon-xl">
                <div className="avatar-icon rounded">
                  <img src={this.state.src} onError={this.onError} />
                </div>
            </div>
            <div>
                <h5 className="menu-header-title">{this.props.enseigne.name}</h5>
            </div>
            <div className="menu-header-btn-pane">
            <Link to={{
                pathname:`/dashboard/support/${this.props.enseigne.id}`,params:{...this.props.enseigne.name}, 
                state: {
                    enseigne: this.props,
                    name:this.props.enseigne.name,
   
                //    channel_id : this.props.echannel.map((id_channel) => id_channel) 
                }
                }}>
                    <Button size="sm" color="primary" className="mr-2" >
                                        Voir
                                    </Button>

                </Link>
                
            </div>
        </div>
    </div>
</div>
<ListGroup flush>
    <ListGroupItem className="bg-warm-flame">
        <div className="widget-content p-0">
            <div className="widget-content-wrapper">
                <div className="widget-content-left mr-3">
                    <div className="icon-wrapper m-0">
                        <b className="text-primary">
                            
                        </b>
                    </div>
                </div>
                <div className="widget-content-left">
                    <div className="widget-heading text-dark opacity-7">
                    <span className="pr-2">
                        <b className="text-danger">{this.props.enseigne.nbcampagne}    </b>Campagnes en cours 
                    </span>
                    </div>
                    <div className="widget-subheading opacity-10">
                    <span className="pr-2">
                        <b className="text-danger">{this.props.enseigne.nbcampagne - this.props.enseigne.nbaffcamp}</b>Campagne(s) non affiliée(s) 
                    </span>



                    </div>
                </div>
            </div>
        </div>
        </ListGroupItem>
    </ListGroup>                      
</Card>
</Col>
        );
    }
}

export default Box;
