import { interfaceConstants } from '../_constants';
import { userService,interfaceService } from '../_services';
import { alertActions } from '.';
import { history } from '../_helpers';
import { eventsPlaylist } from '../_reducers/eventsPlaylist.reducer';
import { store } from 'react-notifications-component';
import { campagne } from '../_reducers/campagne.reducer';

export const interfaceActions = {
    getMedias,
    addMedia,
    addCampagne,
    getCampagnes,
    getCampagne,
    getEnseignes,
    getEnseigne,
    getPdv,
    getSecteurs,
    getEventsPlaylist,
    affiliateMedia,
    playVideo,
    deleteCampagne,
    deleteMedia,
    updatePlaylist,
    unAffiliate,
    getClients,
    getCanaux,
    getpdvClient
};


function getpdvClient(id) {
    return dispatch => {
        dispatch(request(id));

        interfaceService.getpdvClient(id)
            .then(
                pdvclient => dispatch(success(pdvclient)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: interfaceConstants.PDVCLIENT_REQUEST } }
    function success(pdvclient) { return { type: interfaceConstants.PDVCLIENT_SUCCESS, pdvclient } }
    function failure(error) { return { type: interfaceConstants.PDVCLIENT_FAILURE, error } }
}
function getCanaux(id) {
    return dispatch => {
        dispatch(request(id));

        interfaceService.getCanaux(id)
            .then(
                canaux => dispatch(success(canaux)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: interfaceConstants.CANAUX_REQUEST } }
    function success(canaux) { return { type: interfaceConstants.CANAUX_SUCCESS, canaux } }
    function failure(error) { return { type: interfaceConstants.CANAUX_FAILURE, error } }
}

function getClients() {
    return dispatch => {
        dispatch(request());

        interfaceService.getClients()
            .then(
                clients => dispatch(success(clients)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: interfaceConstants.CLIENTS_REQUEST } }
    function success(clients) { return { type: interfaceConstants.CLIENTS_SUCCESS, clients } }
    function failure(error) { return { type: interfaceConstants.CLIENTS_FAILURE, error } }
}

function getEnseignes() {
    return dispatch => {
        dispatch(request());

        interfaceService.getEnseignes()
            .then(
                enseignes => dispatch(success(enseignes)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: interfaceConstants.ENSEIGNES_REQUEST } }
    function success(enseignes) { return { type: interfaceConstants.ENSEIGNES_SUCCESS, enseignes } }
    function failure(error) { return { type: interfaceConstants.ENSEIGNES_FAILURE, error } }
}


function getEnseigne(id) {
    return dispatch => {
        dispatch(request(id));

        interfaceService.getEnseigne(id)
            .then(
                enseigne => dispatch(success(enseigne)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request() { return { type: interfaceConstants.ENSEIGNE_REQUEST } }
    function success(enseigne) { return { type: interfaceConstants.ENSEIGNE_SUCCESS, enseigne } }
    function failure(error) { return { type: interfaceConstants.ENSEIGNE_FAILURE, error } }
}

function getPdv(id) {
    return dispatch => {
        dispatch(request(id));

        interfaceService.getPdv(id)
            .then(
                pdvs=> dispatch(success(pdvs)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request() { return { type: interfaceConstants.PDV_REQUEST } }
    function success(pdvs) { return { type: interfaceConstants.PDV_SUCCESS, pdvs } }
    function failure(error) { return { type: interfaceConstants.PDV_FAILURE, error } }
}


function addCampagne(name, datefrom, dateto, timefrom, timeto, selectOptions, enseigne_id) {
    return dispatch => {
        dispatch(request({ name, datefrom, dateto, timefrom, timeto, selectOptions, enseigne_id }));

        interfaceService.addCampagne(name, datefrom, dateto, timefrom, timeto, selectOptions, enseigne_id)
            .then(
                campagne => { 
                    dispatch(success(campagne));

                    store.addNotification({
                        title: "[Notification utilisateur]",
                        message: "Campagne ajoutée avec succès",
                        type: "success",
                        insert: "top",
                        container: "top-center",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                          duration: 10000,
                          onScreen: true
                        }
                      });

                },
                error => {
                    dispatch(failure(error.toString()));
                    store.addNotification({
                        title: "Oops une erreur est survenue !",
                        message: error.toString(),
                        type: "danger",
                        insert: "top",
                        container: "top-center",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                          duration: 10000,
                          onScreen: true
                        }
                      });
                    
                }
            );
    };

    function request() { return { type: interfaceConstants.ADDCAMPAGNE_REQUEST } }
    function success(campagne) { return { type: interfaceConstants.ADDCAMPAGNE_SUCCESS, campagne } }
    function update(campagnes) { return { type: interfaceConstants.ADDCAMPAGNE_SUCCESS, campagnes } }
    function failure(error) { return { type: interfaceConstants.ADDCAMPAGNE_FAILURE, error } }

}
function addMedia(media, enseigne_id, secteur, langue, webview) {
    return dispatch => {
        dispatch(request({ media, enseigne_id,secteur, langue, webview }));

        interfaceService.addMedia(media, enseigne_id, secteur, langue, webview)
            .then(
                media => { 
                    dispatch(success(media));

                    store.addNotification({
                        title: "[Notification utilisateur]",
                        message: "Upload Media Ok ",
                        type: "success",
                        insert: "top",
                        container: "top-center",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                          duration: 10000,
                          onScreen: true
                        }
                      });

                },
                error => {
                    dispatch(failure(error.toString()));
                    store.addNotification({
                        title: "Oops une erreur est survenue !",
                        message: error.toString(),
                        type: "danger",
                        insert: "top",
                        container: "top-center",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                          duration: 10000,
                          onScreen: true
                        }
                      });

                }
            );
    };

    function request() { return { type: interfaceConstants.ADDMEDIA_REQUEST } }
    function success(media) { return { type: interfaceConstants.ADDMEDIA_SUCCESS, media } }
    function update(medias) { return { type: interfaceConstants.ADDMEDIA_SUCCESS, media } }
    function failure(error) { return { type: interfaceConstants.ADDMEDIA_FAILURE, error } }

}

function getCampagnes(id) {
    return dispatch => {
        dispatch(request(id));

        interfaceService.getCampagnes(id)
            .then(
                campagnes => dispatch(success(campagnes)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request() { return { type: interfaceConstants.CAMPAGNES_REQUEST } }
    function success(campagnes) { return { type: interfaceConstants.CAMPAGNES_SUCCESS, campagnes } }
    function failure(error) { return { type: interfaceConstants.CAMPAGNES_FAILURE, error } }
}

function getCampagne(id) {
    return dispatch => {
        dispatch(request(id));

        interfaceService.getCampagne(id)
            .then(
               // campagne => dispatch(success(campagne)),
                campagne => dispatch(editForm(campagne)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request() { return { type: interfaceConstants.CAMPAGNE_REQUEST } }
    function success(campagne) { return { type: interfaceConstants.CAMPAGNE_SUCCESS, campagne } }
    function editForm(campagne) { return { type: interfaceConstants.CAMPAGNE_EDIT, campagne } }
    function failure(error) { return { type: interfaceConstants.CAMPAGNE_FAILURE, error } }
}
function getEventsPlaylist(id){
    return dispatch => {
        dispatch(request(id));

        interfaceService.getEventsPlaylist(id)
            .then(
                eventsPlaylist => dispatch(success(eventsPlaylist)),
                error => dispatch(failure(error.toString()))
            );
    };

              
    
    function request() { return { type: interfaceConstants.EVENTS_REQUEST } }
    function success(eventsPlaylist) { return { type: interfaceConstants.EVENTS_SUCCESS, eventsPlaylist } }
    function failure(error) { return { type: interfaceConstants.EVENTS_FAILURE, error } }

}


function updatePlaylist(id,canal){
    return dispatch => {
        dispatch(request(id,canal));

        interfaceService.updatePlaylist(id,canal)
            .then(
                playlistID => { 
                    dispatch(success(playlistID))
                
                        store.addNotification({
                            title: "[Notification utilisateur]",
                            message: "Upload playlist Ok ",
                            type: "success",
                            insert: "top",
                            container: "top-center",
                            animationIn: ["animated", "fadeIn"],
                            animationOut: ["animated", "fadeOut"],
                            dismiss: {
                              duration: 10000,
                              onScreen: true
                            }
                          });
    
                        },
                error => {
                    dispatch(failure(error.toString()))
                
                
                }
            );
    };
    function request() { return { type: interfaceConstants.UPDATEPLAYLIST_REQUEST } }
    function success(playlistID) { return { type: interfaceConstants.UPDATEPLAYLIST_SUCCESS, playlistID } }
    function failure(error) { return { type: interfaceConstants.UPDATEPLAYLIST_FAILURE, error } }
    
}


function getSecteurs(id) {
    return dispatch => {
        dispatch(request(id));

        interfaceService.getSecteurs(id)
            .then(
                secteurs => dispatch(success(secteurs)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: interfaceConstants.SECTEURS_REQUEST } }
    function success(secteurs) { return { type: interfaceConstants.SECTEURS_SUCCESS, secteurs } }
    function failure(error) { return { type: interfaceConstants.SECTEURS_FAILURE, error } }
}

function getMedias(id) {
    return dispatch => {
        dispatch(request(id));

        interfaceService.getMedias(id)
            .then(
                medias => dispatch(success(medias)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request() { return { type: interfaceConstants.MEDIAS_REQUEST } }
    function success(medias) { return { type: interfaceConstants.MEDIAS_SUCCESS, medias } }
    function failure(error) { return { type: interfaceConstants.MEDIAS_FAILURE, error } }
}

function affiliateMedia(idEnseigne,idMedia, idCampagne, pdvs,client_id,duration,canal) {
    return dispatch => {
        dispatch(request({ idEnseigne, idMedia, idCampagne, pdvs,client_id,duration,canal}));

        interfaceService.affiliateMedia(idEnseigne, idMedia, idCampagne, pdvs,client_id,duration,canal)
            .then(
                assigned => { 
                    dispatch(success(assigned));

                    store.addNotification({
                        title: "Media ",
                        message: "Media affilié avec succès",
                        type: "success",
                        insert: "top",
                        container: "top-center",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                          duration: 10000,
                          onScreen: true
                        }
                      });

                },
                error => {
                    dispatch(failure(error.toString()));
                    store.addNotification({
                        title: "Oops une erreur est survenue !",
                        message: error.toString(),
                        type: "danger",
                        insert: "top",
                        container: "top-center",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                          duration: 10000,
                          onScreen: true
                        }
                      });
                }
            );
    };

    function request() { return { type: interfaceConstants.AFFILIATEMEDIA_REQUEST } }
    function success(assigned) { return { type: interfaceConstants.AFFILIATEMEDIA_SUCCESS, assigned } }
    function update() { return { type: interfaceConstants.ADDMEDIA_SUCCESS,  } }
    function failure(error) { return { type: interfaceConstants.AFFILIATEMEDIA_FAILURE, error } }

}

function playVideo(id) {
    return dispatch => {
        dispatch(request(id));

        interfaceService.playVideo(id)
            .then(
                media => dispatch(success(media)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request() { return { type: interfaceConstants.MEDIAS_REQUEST } }
    function success(media) { return { type: interfaceConstants.MEDIAS_SUCCESS, media } }
    function failure(error) { return { type: interfaceConstants.MEDIAS_FAILURE, error } }
}

function unAffiliate(id){
    return dispatch => {
        dispatch(request(id));

        interfaceService.unAffiliate(id)
            .then(
                campagne => dispatch(success(campagne)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: interfaceConstants.UNAFFILIATE_REQUEST, id } }
    function success(campagne) { return { type: interfaceConstants.UNAFFILIATE_SUCCESS, campagne } }
    function failure(id, error) { return { type: interfaceConstants.UNAFFILIATE_FAILURE, id, error } }


}



function deleteCampagne(id) {
    return dispatch => {
        dispatch(request(id));

        interfaceService.deleteCampagne(id)
            .then(
                campagne => dispatch(success(id)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: interfaceConstants.DELETE_CAMPAGNE_REQUEST, id } }
    function success(id) { return { type: interfaceConstants.DELETE_CAMPAGNE_SUCCESS, id } }
    function failure(id, error) { return { type: interfaceConstants.DELETE_CAMPAGNE_FAILURE, id, error } }
}

function deleteMedia(id) {
    return dispatch => {
        dispatch(request(id));

        interfaceService.deleteMedia(id)
            .then(
                campagne => dispatch(success(id)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: interfaceConstants.DELETE_MEDIA_REQUEST, id } }
    function success(id) { return { type: interfaceConstants.DELETE_MEDIA_SUCCESS, id } }
    function failure(id, error) { return { type: interfaceConstants.DELETE_MEDIA_FAILURE, id, error } }
}  
