import React, {Fragment} from 'react';
import {Route} from 'react-router-dom';

// DASHBOARDS

import {Interface} from './Interface/';

import {Settings} from './Settings/';
import {Monitoring} from './Monitoring/';

import {Support} from './Interface/Support';
import AppHeader from '../Layout/AppHeader/';
import AppSidebar from '../Layout/AppSidebar/';
import AppFooter from '../Layout/AppFooter/';


const Dashboards = ({match}) => (
    <Fragment>
        <AppHeader/>
        <div className="app-main">
            <AppSidebar/>
            <div className="app-main__outer">
                <div className="app-main__inner">
                    <Route path={`${match.url}/support/:id`} component={Support}/>

                    <Route path={`${match.url}/interface`} component={Interface}/>

                    <Route path={`${match.url}/monitoring`} component={Monitoring}/>
                    
                    <Route path={`${match.url}/settings`} component={Settings}/>


                </div>
                <AppFooter/>
            </div>
        </div>
    </Fragment>
);

export default Dashboards;