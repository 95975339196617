import React, {Fragment} from 'react';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import {
    Row, Col,
    Button,
    Card, CardBody,
    UncontrolledButtonDropdown, DropdownItem, DropdownMenu, DropdownToggle,
    ModalHeader, ModalBody, ModalFooter
} from 'reactstrap';

import BootstrapTable from 'react-bootstrap-table-next';
import filterFactory, {textFilter} from 'react-bootstrap-table2-filter';

import PageTitle from '../../../Layout/AppMain/PageTitle';
import TimeAgo from 'react-timeago'
import frenchStrings from 'react-timeago/lib/language-strings/fr'
import buildFormatter from 'react-timeago/lib/formatters/buildFormatter'
import paginationFactory, { PaginationProvider, PaginationTotalStandalone, PaginationListStandalone } from 'react-bootstrap-table2-paginator';
import DrawerBigExample from '../../../Layout/AppHeader/Components/TabsContent/DrawerBigExample';
import Drawer from 'react-motion-drawer';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Rodal from 'rodal';

import  MonitoringPlayer from '../Player';

const formatter = buildFormatter(frenchStrings)

let nameFilter;

class GridTables extends React.Component {

constructor(props) {
    super(props);
    this.state = {
        active: false,
        openLeft: false,
        openRight: false,
        relativeWidth: false,
        width: 600,
        noTouchOpen: false,
        noTouchClose: false,
        dropdownOpen: false,
        visible: false,
        animation: 'zoom'
    };
    //this.monitoringPlayer = this.monitoringPlayer.bind(this);

}
show(animation) {
    this.setState({
        animation,
        visible: true
    });
}

hide() {
    this.setState({visible: false});
}

incrementChildCounter = () => {
    this.props.dispatch({ type: "INCREMENT_CHILD" });
  };
openModal(id) {
    this.refs.monitoringPlayer.toggle(id);
  }
/*
monitoringPlayer(event){

    const { name, openRight,openLeft, active} = this.state;
console.log(event)
this.setState({openRight: !this.state.openRight, openLeft: false,active:true})
}*/

//const GridTables = (props) => {
    render() {
        const { data, page, sizePerPage, onTableChange, totalSize,openRight} = this.props;
 
        const columns = [
            {
                dataField: 'id',
                text: 'Player ID',
                sort: false,
                //filter: textFilter(),
        
        
            },
          
            {
                dataField: 'description',
                text: 'Description',
                sort: false,
                //filter: textFilter(),
        
            },
            {
                dataField: 'type',
                text: 'Type',
                sort: false,
                //filter: textFilter(),
                align: 'center',
        
            },
            {
                dataField: 'site',
                text: 'Site',
                sort: false,
                //filter: textFilter(),
                align: 'center',
//                filter: textFilter({
  //                  getFilter: filter => {
     //                 nameFilter = filter;
        //            }
           //       }),
                formatter: (cellContent, row) => {
                    if(row.site)
                    return (
                        <div>
                                    {row.site.name}
                        </div>
                    );
                }
            
            },
        
            {
                dataField: 'timesince',
                text: 'Time since',
                sort: false,
                //filter: textFilter(),
                align: 'center',
                formatter: (cellContent, row) => {
                    return (
                        <div>
                                  <TimeAgo date={row.last_seen} formatter={formatter} />  
                        </div>
                    );
                }
        
            },
            {
                dataField: 'last_seen',
                text: 'Last seen ',
                sort: false,
                //filter: textFilter(),
                align: 'center',
        
            },
        
            {
                dataField: 'status',
                sort: false,
                //filter: textFilter(),

                isDummyField: false,
                align: 'center',
                text: 'Status',
                formatter: (cellContent, row) => {
                    return (
                        <div className="d-block w-100 text-center">
                            <span className="badge badge-success"> UP</span>
                        </div>
                    );
                }
            },
            {
                dataField: 'actions',
                isDummyField: true,
                sort: false,
                //filter: textFilter(),

                align: 'center',
                text: 'Actions',
                formatter: (cellContent, row) => {
                    return (
                        <div>
                            <div className="d-block w-100 text-center">
                                {/* whenClicked is a property not an event, per se. 

                            <Button size="sm" color="primary" className="mr-2" 
                               active={openRight}
                               type="elastic"
                               onClick={() =>  {this.openModal(row.id) }}
                            
                            >Administrer</Button>
                            */}
                            </div>
                        </div>
                    );
                }
            },
        
        ];
        
        const defaultSorted = [{
            dataField: 'last_seen',
            order: 'desc'
        }];

    return (
       
        
                <Row>
                    <Col md="12">
                                <div className="table-responsive">
                                    <BootstrapTable
                                        bootstrap4
                                        remote
                                        keyField="id"
                                        data={data.data}
                                        columns={columns}
                                        filter={filterFactory()}
                                        pagination={ paginationFactory({ page, sizePerPage, totalSize }) }
                                        onTableChange={ onTableChange }

                                    />
                                </div>
                
                                <MonitoringPlayer ref="monitoringPlayer" />
    
                    </Col>
                </Row>
                
    );
};
    
}
export default GridTables;