import { interfaceConstants } from '../_constants';

export function pdvs(state = {}, action) {
  switch (action.type) { 
     case interfaceConstants.PDV_REQUEST:
      return {
        loading: true
      };
    case interfaceConstants.PDV_SUCCESS:
      return {
        items: action.pdvs
      };
    case interfaceConstants.PDV_FAILURE:
      return { 
        error: action.error
      };
    default:
      return state
  }
}