import React, {Fragment} from 'react';
import {connect} from 'react-redux';
import cx from 'classnames';

import ResizeDetector from 'react-resize-detector';
import { Router, Route, Switch, Redirect,withRouter } from 'react-router-dom';

import { history } from '../_helpers';
import { alertActions } from '../_actions';
import { PrivateRoute } from '../_components';
import { Dashboard } from '../Dashboard';
import {LoginPage}  from '../LoginPage';

import AppMain from '../Layout/AppMain';
//import LoginPage from '../DemoPages/UserPages/LoginBoxed';

class Main extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            closedSmallerSidebar: false
        };

    }

    render() {
        let {
            colorScheme,
            enableFixedHeader,
            enableFixedSidebar,
            enableFixedFooter,
            enableClosedSidebar,
            closedSmallerSidebar,
            enableMobileMenu,
            enablePageTabsAlt,
            alert,
        } = this.props;

        return (
            <ResizeDetector
                handleWidth
                render={({ width }) => (
                    <Fragment>
                         {alert &&
                            <div className={`alert ${alert.type}`}>{alert.message}</div>
                        }
                        <div className={cx(
                            "app-container app-theme-" + colorScheme,
                            {'fixed-header': enableFixedHeader},
                            {'fixed-sidebar': enableFixedSidebar || width < 1250},
                            {'fixed-footer': enableFixedFooter},
                            {'closed-sidebar': enableClosedSidebar || width < 1250},
                            {'closed-sidebar-mobile': closedSmallerSidebar || width < 1250},
                            {'sidebar-mobile-open': enableMobileMenu},
                            {'body-tabs-shadow-btn': enablePageTabsAlt},
                        )}> 
                       
                            <AppMain/>
                        </div>                

                    </Fragment>
                )}
            />
        )
    }
}

const mapStateToProp = state => ({
    colorScheme: state.ThemeOptions.colorScheme,
    enableFixedHeader: state.ThemeOptions.enableFixedHeader,
    enableMobileMenu: state.ThemeOptions.enableMobileMenu,
    enableFixedFooter: state.ThemeOptions.enableFixedFooter,
    enableFixedSidebar: state.ThemeOptions.enableFixedSidebar,
    enableClosedSidebar: state.ThemeOptions.enableClosedSidebar,
    enablePageTabsAlt: state.ThemeOptions.enablePageTabsAlt,
    alert: state.alert.alert
});
const actionCreators = {
    clearAlerts: alertActions.clear
};
//const connectedApp = withRouter(connect(mapStateToProp,actionCreators)(Main));
const connectedApp = connect(mapStateToProp,actionCreators)(Main);
export { connectedApp as Main };


//export default withRouter(connect(mapStateToProp)(Main));


//export default withRouter(connect(mapStateToProp,actionCreators)(Main));

//with router ???