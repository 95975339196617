
export const MainNav = [
    {
        icon: 'pe-7s-display1',
        label: 'Clients',
        to: '/dashboards/sales',
    },
    {
        icon: 'pe-7s-display1',
        label: 'Utilisateurs',
        to: '/dashboards/analytics',
    },
];
export const ComponentsNav = [
    {
        icon: 'pe-7s-display1',
        label: 'Monitoring players',
        to: '/dashboard/monitoring',
    },
];
export const FormsNav = [
    {
       
    },

];
export const WidgetsNav = [
    {
        icon: 'pe-7s-id',
        label: 'Gestion de diffusion',
        to: '/dashboard/interface',
    },

];


export const SettingsNav = [
    {
        icon: 'pe-7s-settings',
        label: 'Mon compte',
        to: '/dashboard/settings',
    },

];

export const ChartsNav = [
   
];
