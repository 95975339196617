 
const now = new Date()

export default [
  {
    id: 0,
    title: 'All Day Event very long title',
    allDay: true,
    start: new Date(2020, 6, 2),
    end: new Date(2020, 6, 2),
  },
  
  {
    id: 14,
    title: 'Today',
    allDay: true,

    start: new Date(new Date().setHours(new Date().getHours() - 3)),
    end: new Date(new Date().setHours(new Date().getHours() + 3)),
  },
  {
    id: 15,
    title: 'Today',
    allDay: true,

    start: new Date(new Date().setHours(new Date().getHours() - 3)),
    end: new Date(new Date().setDate(new Date().getDay() + 7)),
  },

 
]
