import { interfaceConstants } from '../_constants';

export function medias(state = {}, action) {
  switch (action.type) { 
     case interfaceConstants.MEDIAS_REQUEST:
      return {
        loading: true
      };
      case interfaceConstants.ADDMEDIA_REQUEST:
          return {
            ...state,
            loading: true
        };
             
      case interfaceConstants.ADDMEDIA_SUCCESS:
        let toto = {...state}
          return {
              ...state,
              medias:{
                data : [
                  ...state.medias.data.slice(0, 0),
                  action.media,
                  ...state.medias.data.slice(0),

                ],              
            }
          };

      case interfaceConstants.ADDMEDIA_FAILURE:
        return {
          error: action.error
        };
            
    case interfaceConstants.MEDIAS_SUCCESS:
      return {

        ...state,
        loading: false,
        medias: action.medias
      };
    case interfaceConstants.MEDIAS_FAILURE:
      return { 
        error: action.error
      };
      case interfaceConstants.DELETE_MEDIA_REQUEST:
            // add 'deleting:true' property to user being deleted
            console.log("ACTION RESPONSE");
            console.log(state);
            return {
              ...state,
              medias: {
                data :
                state.medias.data.map(media =>
                media.id === action.id
                  ? { ...media, deleting: true }
                  : media
              )
                }
                
            };
      case interfaceConstants.DELETE_MEDIA_SUCCESS:
            // remove deleted user from state
            console.log(state);
             
            return {
              medias: {
                data: state.medias.data.filter(media => media.id !== action.id)
              }
              };
    default:
      return state
  }
}