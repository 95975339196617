import React, {Component, Fragment} from 'react';

import {
    Breadcrumb, BreadcrumbItem
} from 'reactstrap';

import {
    faHome

} from '@fortawesome/free-solid-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

export default class TitleComponent3 extends Component {
    // add props for linking
    render() {
        let {
            breads,
        } = this.props;
        return (
            <Fragment>
                <Breadcrumb>
                    <BreadcrumbItem><a href="/dashboard">
                        <FontAwesomeIcon icon={faHome}/></a>
                    </BreadcrumbItem>

                    {breads && breads.map((bread, i) => {     

                        if(bread.href == ""){
                            return( <BreadcrumbItem>
                                {bread.name}
                            </BreadcrumbItem>)
    
                        }else{
                            let hr = "/dashboard/" + bread.href;
                            return( <BreadcrumbItem>
                                <a href={hr}>{bread.name}</a>
                            </BreadcrumbItem>)
    
                        }
                    })}
                
                </Breadcrumb>
            </Fragment>
        );
    }
}