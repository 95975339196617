export default {
    FR: {
        title: 'Manager v3',
        email: 'Courriel',
        password: 'Mot de passe',
        name: 'Nom',
    },
GB: {
    title: 'Manager v3',
    email: 'Email',
    passsword: 'Password',
    name: 'Name',
},
};