import { authHeader } from '../_helpers';
import socketIOClient from "socket.io-client";

const  URL = "https://d2sapi.opentlv.com/interface";

const ENDPOINT = "https://d2sapi.opentlv.com";


export const interfaceService = {
    getMedias,
    affiliateMedia,
    getCampagnes,
    getCampagne, 
    addCampagne,
    getEnseignes,
    getEnseigne,
    getPdv,
    getSecteurs,
    getEventsPlaylist,
    addMedia,
    playVideo,
    deleteCampagne,
    deleteMedia,
    unAffiliate,
    updatePlaylist,
    logout,
    getClients,
    getCanaux,
    getpdvClient

};
function getCanaux(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${URL}/canaux/${id}`, requestOptions)
    .then(handleResponse);
}


function getpdvClient(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${URL}/pdvclient/${id}`, requestOptions)
    .then(handleResponse);
}




function getClients() {
    
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${URL}/clients`, requestOptions)
    .then(handleResponse)
    .then(clients => {
        localStorage.setItem('clients', JSON.stringify(clients));

        return clients;
    });;
}

function logout() {
    // remove user from local storage to log user out
    localStorage.removeItem('user');
}

function affiliateMedia(idEnseigne, idMedia,idCampagne,pdvs,client_id,duration,canal){ 
    const requestOptions = {

    method: 'POST',
    headers: authHeader("application/json"),
    //headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({idEnseigne,idMedia,idCampagne,pdvs,client_id,duration,canal})
};

return fetch(`${URL}/affiliate`, requestOptions)
    .then(handleResponse);
}

function addCampagne(name, datefrom, dateto, timefrom, timeto, selectOptions, enseigne_id) {
  
  
    const requestOptions = {
        method: 'POST',
        headers: authHeader("application/json"),
        //headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({name, datefrom, dateto, timefrom, timeto, selectOptions, enseigne_id })
    };

    return fetch(`${URL}/campagne`, requestOptions)
        .then(handleResponse)
}

function addMedia(media, enseigne_id, secteur, langue,webview) {
    var formData = new FormData();
    formData.append('file', media[0]);
    formData.append('enseigne_id', enseigne_id);
    formData.append('secteur', secteur);
    formData.append('langue', langue);
    formData.append('webview', webview);

    const requestOptions = {
        method: 'POST',
        headers: authHeader("multipart/form-data"),
        //headers: { 'Content-Type': 'application/json' },
        body: formData
    };
    delete requestOptions.headers['Content-Type'];

    return fetch(`${URL}/media`, requestOptions)
        .then(handleResponse);
}

function getEnseignes() {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${URL}/enseignes`, requestOptions)
    .then(handleResponse)
    .then(enseignes => {
        localStorage.setItem('enseignes', JSON.stringify(enseignes));

        return enseignes;
    });;
}

function getMedias(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${URL}/medias/${id}`, requestOptions)
    .then(handleResponse)
    .then(medias => {
    
        return medias;
    });
}
function playVideo(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${URL}/play/media/${id}`, requestOptions)
    .then(handleResponse)
    .then(media => {
      

        return media;
    });
}


function getPdv(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${URL}/pdvs/${id}`, requestOptions)
    .then(handleResponse);
}

function getCampagnes(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${URL}/campagnes/${id}`, requestOptions)
    .then(handleResponse);
}
function getCampagne(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${URL}/campagne/edit/${id}`, requestOptions)
    .then(handleResponse);
}
function getEnseigne(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${URL}/enseigne/${id}`, requestOptions).then(handleResponse);
}

function getEventsPlaylist(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${URL}/events/${id}`, requestOptions).then(handleResponse)
 
}

function getSecteurs(id) {
    const requestOptions = {
        method: 'GET',
        headers: authHeader()
    };

    return fetch(`${URL}/secteurs/${id}`, requestOptions)
    .then(handleResponse);
}
function deleteCampagne(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${URL}/campagne/${id}`, requestOptions).then(handleResponse);
}

function unAffiliate(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${URL}/unaffiliate/${id}`, requestOptions).then(handleResponse);
}


function deleteMedia(id) {
    const requestOptions = {
        method: 'DELETE',
        headers: authHeader()
    };

    return fetch(`${URL}/media/${id}`, requestOptions).then(handleResponse);
}

function updatePlaylist(id,canal){ 
    const requestOptions = {

    method: 'POST',
    headers: authHeader("application/json"),
    //headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify({id,canal})
};

return fetch(`${URL}/update-timestamp`, requestOptions)
    .then(handleResponse)    
    .then(playlistID=> { 

        const socket = socketIOClient(ENDPOINT);
        socket.on("connect", data => {
            socket.emit('subscribe-to-channel', 'playlist'+ playlistID);
            socket.emit('reloadMedialist',playlistID);

        });
      });

}

function handleResponse(response) {
    return response.text().then(text => {
        const data = text && JSON.parse(text);
        if (!response.ok) {
            if (response.status === 401) {
                logout();
            }

            const error = (data && data.message) || response.statusText;
            return Promise.reject(error);
        }
        return data;
    });
}