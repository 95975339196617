import { interfaceConstants } from '../_constants';
import update from 'immutability-helper';


export function assigned(state = {}, action) {
  switch (action.type) {
    case interfaceConstants.AFFILIATEMEDIA_REQUEST:
      return {
        loading: true
      };
   
    case interfaceConstants.AFFILIATEMEDIA_FAILURE:
      return {};
    default:
      return state
  }
}