import { interfaceConstants } from '../_constants';
import update from 'immutability-helper';

function transformDateFormat(json) {
  const eventPlaylist = {
      ...json,
      id: json.data._id,
      // date: new Date(moment(json.data.date).format('yyyy-MM-dd'))
      date: new Date(json.data.date)
  }
  return eventPlaylist;
}
export function eventsPlaylist(state = {}, action) {
  switch (action.type) {
    case interfaceConstants.EVENTS_REQUEST:
      return {
        loading: true
      };
    case interfaceConstants.EVENTS_SUCCESS:

        for (let i = 0; i < action.eventsPlaylist.length; i++) {
          action.eventsPlaylist[i].end  =  new Date( action.eventsPlaylist[i].end)
          action.eventsPlaylist[i].start  = new Date( action.eventsPlaylist[i].start)

        }

      return {
        eventsPlaylist: action.eventsPlaylist
      };
    case interfaceConstants.EVENTS_FAILURE:
      return {};
      case interfaceConstants.UPDATEPLAYLIST_REQUEST:
        return {};
      case interfaceConstants.UPDATEPLAYLIST_SUCCESS:
          return {
            playlistID: action.playlistID

          };
      case interfaceConstants.UPDATEPLAYLIST_FAILURE:
          return {};

      
      
    default:
      return state
  }
}