import { interfaceConstants } from '../_constants';

export function enseignes(state = {}, action) {
  switch (action.type) {
    case interfaceConstants.ENSEIGNES_REQUEST:
      return {
        loading: true
      };
    case interfaceConstants.ENSEIGNES_SUCCESS:
      return {
        items: action.enseignes
      };
    case interfaceConstants.ENSEIGNES_FAILURE:
      return { 
        error: action.error
      };
    
    default:
      return state
  }
};
export function enseigne(state = {}, action) {
  switch (action.type) { 
     case interfaceConstants.ENSEIGNE_REQUEST:
      return {
        loading: true
      };
    case interfaceConstants.ENSEIGNE_SUCCESS:
      return {
        items: action.enseigne
      };
    case interfaceConstants.ENSEIGNE_FAILURE:
      return { 
        error: action.error
      };
    default:
      return state
  }
}