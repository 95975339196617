import { monitoringConstants } from '../_constants';

export function players(state = {}, action) {
  switch (action.type) {
    case monitoringConstants.PLAYERS_REQUEST:
      return {
        loading: true
      };
    case monitoringConstants.PLAYERS_SUCCESS:

      return {
        items: action.players
      };
    case monitoringConstants.PLAYERS_FAILURE:
      return { 
        error: action.error
      };
      
    default:
      return state
  }
};
