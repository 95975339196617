import { monitoringConstants } from '../_constants';
import { userService,monitoringService } from '../_services';
import { alertActions } from '.';
import { history } from '../_helpers';
import { eventsPlaylist } from '../_reducers/eventsPlaylist.reducer';
import { store } from 'react-notifications-component';
import { players } from '../_reducers/players.reducer';

export const monitoringActions = {
    getPlayers,
    searchPlayers,
    getInfosPlayer

};

function getInfosPlayer(id){
    return dispatch => {
        dispatch(request(id));

        monitoringService.getInfosPlayer(id)
            .then(
                playerInfos => dispatch(success(id)),
                error => dispatch(failure(error.toString()))
            );
    };
    function request() { return { type: monitoringConstants.PLAYERS_GET_INFOS_REQUEST } }
    function success(playlistID) { return { type: monitoringConstants.PLAYERS_GET_INFOS_SUCCESS, playlistID } }
    function failure(error) { return { type: monitoringConstants.PLAYERS_GET_INFOS_FAILURE, error } }
    
}


function getPlayers(page ,sizePerPage) {
    return dispatch => {
        dispatch(request());

        monitoringService.getPlayers(page ,sizePerPage)
            .then(
                players => dispatch(success(players)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: monitoringConstants.PLAYERS_REQUEST } }
    function success(players) { return { type: monitoringConstants.PLAYERS_SUCCESS, players } }
    function failure(error) { return { type: monitoringConstants.PLAYERS_FAILURE, error } }
}
function searchPlayers(term,row,page ,sizePerPage) {
    return dispatch => {
        dispatch(request());

        monitoringService.searchPlayers(term,row,page ,sizePerPage)
            .then(
                players => dispatch(success(players)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: monitoringConstants.PLAYERS_SEARCH_REQUEST } }
    function success(players) { return { type: monitoringConstants.PLAYERS_SEARCH_SUCCESS, players } }
    function failure(error) { return { type: monitoringConstants.PLAYERS_SEARCH_FAILURE, error } }
}

