import React, { Component } from 'react';
import { interfaceActions } from '../../../../_actions';
import { connect } from 'react-redux';
import {authHeader} from '../../../../_helpers';
import axios from 'axios';
import Media from '../Media'
import Flag from 'react-flagkit';
import VideoMp from './VideoMp'

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  Col, Row, Button,
  Card, Nav, NavLink, NavItem,
  DropdownMenu, DropdownItem,ListGroup, ListGroupItem,CardHeader, 
  CardBody,CustomInput, InputGroup, InputGroupAddon, Input,
  Table, UncontrolledButtonDropdown, DropdownToggle,CardTitle,
  ButtonGroup,Form, FormGroup, Label,FormText, Container,CardFooter,
  Badge
} from 'reactstrap';

class DataTable extends Component {
  constructor(props) {
    super(props);
    const store = props
    console.log("STORE")
    console.log(props)
    // grab our starting state
//      this.state = store.getState()
    this.OnDropMedia = this.OnDropMedia.bind(this);
    this.onDragStart = this.onDragStart.bind(this);
    this.onDragEnd = this.onDragEnd.bind(this);

    this.state = {   
      first_page: 1,
      current_page: 1,
      sorted_column: this.props.columns[0],
      offset: 4,
      order: 'asc',
      targetbox: true
    };

//    console.log()
  }
  
  fetchdata() {

      let config = {
        headers: authHeader()
        
    }
  //  let fetchUrl = `https://apidev.opentlv.com/interface/medias/${this.props.id}/?page=${this.state.current_page}&column=${this.state.sorted_column}&order=${this.state.order}&per_page=${this.props.medias.meta.per_page}`;
    let fetchUrl = `https://d2sapi.opentlv.com/interface/medias/${this.props.id}`;
    /*
   axios.get(fetchUrl,config)
      .then(response => {
            console.log("RESPONSE");  
            console.log(response.data)
            console.log(this.props.medias);  
        this.setState({ medias: response.data});
        console.log(this.props.medias);  
        console.log("RESPONSE END");  

      })
      .catch(e => {
        console.error(e);
      });*/
    //  var medias =  this.props.store.getMedias(this.props.id);
     // console.log(medias);
  }

  changePage(pageNumber) {
    this.setState({ current_page: pageNumber }, () => {this.fetchdata()});
  }
  OnDropMedia(item, targetId){ 


  }
  action(mediaName){
    var fileExtension = mediaName.split('.').pop(); //"pdf"

  }
  columnHead(value) {
    return value.split('_').join(' ').toUpperCase()
  }
  onDragStart = (event, media,) => {
    console.log('dragstart on div: ', media);
    this.setState({targetbox: true})

    event.dataTransfer.setData("media",{'id':media.id,'name':media.name});

}

onDragEnd = (event) => {

  this.setState({targetbox: null})

}
  pagesNumbers() {
    if (!this.props.medias.meta.to) {
      return [];
    }
    let from = this.props.medias.meta.current_page - this.state.offset;
    if (from < 1) {
      from = 1;
    }
    let to = from + (this.state.offset * 2);
    if (to >= this.props.medias.meta.last_page) {
      to = this.props.medias.meta.last_page;
    }
    let pagesArray = [];
    for (let page = from; page <= to; page++) {
      pagesArray.push(page);
    }
    return pagesArray;
  }

  playVideosss(id_catalog,idMedia){
    console.log("playvideo ");    
    this.setState({ idCatalog: id_catalog })
    this.setState({ idMedia: idMedia })

    this.setState({ renderVideo: true })

 //  this.props.playVideo(id);

}

  componentDidMount() {
    console.log("DATATABLES");
    console.log(this.props.medias.medias.current_page);
    
        this.setState({ current_page: this.props.medias.current_page }, () => {this.fetchdata()});
  }

  tableHeads() {
    let icon;
    if (this.state.order === 'asc') {
      icon = <i className="faAngleUp"></i>;
    } else {
      icon = <i className="faAngleDown"></i>;
    }
    return this.props.columns.map(column => {
      return <th className="table-head" key={column} onClick={() => this.sortByColumn(column)}>
        { this.columnHead(column) }
        { column === this.state.sorted_column && icon }
      </th>
    });
  }

  userList() {
      
    if (this.props.medias.medias.data.length) {
      return this.props.medias.medias.data.map(media => {
        //return 
        return <tr key={ media.id } >
        <Media id={media.id} enseigne={this.props.id}/>
        {Object.keys(media).map(key => {
          if(key == 'langue'){
            var td = media[key] ?  <td key={media[key] }><Flag  key={media[key]+media.id} country={media[key]}/></td> :  <td key={media[key]}></td>
            return td
          }else if (key == 'id_catalog') {

          
          }else{
            return  <td key={media[key]}>{ media[key] }</td>        
          

         } 
         })}
        
        <td>

          <UncontrolledButtonDropdown>
            <DropdownToggle caret className="btn-icon btn-icon-only btn btn-link" color="link">
                <i className="lnr-menu-circle btn-icon-wrapper"/>
            </DropdownToggle>
            <DropdownMenu className="rm-pointers dropdown-menu-hover-link">
                <DropdownItem header>Options</DropdownItem>

                {media.name.split('.').pop() != "zip" &&
 <DropdownItem onClick={() => { this.props.playVideo(media.id_catalog,media.id,media.name)}}>

 <i className="dropdown-icon lnr-inbox"> </i>
 <span>Voir</span>
</DropdownItem>
                }      
               
                <DropdownItem onClick={() => { this.props.deleteMedia(media.id) }}>
                    <i className="dropdown-icon lnr-file-empty"> </i>
                    <span>Effacer</span>
                </DropdownItem>
            </DropdownMenu>
        </UncontrolledButtonDropdown>  
        </td>
          
        </tr>

        
      })
    } else {
      return <tr>
        <td colSpan={this.props.columns.length} className="text-center">Aucun media disponible.</td>
      </tr>
    }
  }

  sortByColumn(column) {
    if (column === this.state.sorted_column) {
      this.state.order === 'asc' ? this.setState({ order: 'desc', current_page: this.state.first_page }, () => {this.fetchdata()}) : this.setState({ order: 'asc' }, () => {this.fetchdata()});
    } else {
      this.setState({ sorted_column: column, order: 'asc', current_page: this.state.first_page }, () => {this.fetchdata()});
    }
  }

  pageList() {
    return this.pagesNumbers().map(page => {
      return <li className={ page === this.props.medias.meta.current_page ? 'page-item active' : 'page-item' } key={page}>
        <button className="page-link" onClick={() => this.changePage(page)}>{page}</button>
      </li>
    })
  }

  render() {
    return (
      <div className="data-table ">
 
        <table className="table  table-dark highlight">
          <thead>
            <tr>

            { this.tableHeads() }</tr>
          </thead>
          <tbody>{ this.userList() }</tbody>
        </table>
        { (this.props.medias.data && this.props.medias.data.length > 0) &&
          <nav>
            <ul className="pagination">
            
            </ul>
          </nav>
            
        }

      </div>
    );
  }
}
function mapState(state) {
   
    const { users, authentication, campagnes, enseignes, enseigne, pdvs, secteurs, medias, alert,targetbox} = state;
    const { user } = authentication;
    
    return { user, users, campagnes, enseignes, enseigne, pdvs, secteurs, medias,alert,targetbox,medias};
    }
const actionCreators = {
       // playVideo: interfaceActions.playVideo,
        deleteMedia: interfaceActions.deleteMedia,
    
    }
//export default connect(mapState, actionCreators)(Interface);
const connectedDataTable = connect(mapState, actionCreators)(DataTable);
export { connectedDataTable as DataTable };