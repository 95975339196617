import React from 'react'
import events from './events'
import { Calendar, Views, momentLocalizer } from 'react-big-calendar'
import withDragAndDrop from 'react-big-calendar/lib/addons/dragAndDrop'
import 'moment/locale/fr'; 
import 'react-big-calendar/lib/addons/dragAndDrop/styles.scss'
import 'react-big-calendar/lib/addons/dragAndDrop/styles.css';


import moment from 'moment'
const style = {


}

moment.locale('fr', {
  week: {
      dow: 1,
      doy: 1,
  },
});
const localizer = momentLocalizer(moment)

const DragAndDropCalendar = withDragAndDrop(Calendar)
const now = new Date()

class Dnd extends React.Component {
  constructor(props) {

    console.log("CALENDAR");
    console.log(props);
    super(props)
    this.state = {
      events: props.eventsPlaylist,
      localizer:localizer,
      displayDragItemInCell: true,

    }

    this.moveEvent = this.moveEvent.bind(this)
    this.newEvent = this.newEvent.bind(this)
  }
  handleDragStart = event => {
    this.setState({ draggedEvent: event })
  }

  dragFromOutsideItem = () => {
    return this.state.draggedEvent
  }
  onDropFromOutside = ({ start, end, allDay }) => {
    const { draggedEvent } = this.state

    const event = {
      id: draggedEvent.id,
      title: draggedEvent.title,
      start,
      end,
      allDay: allDay,
    }

    this.setState({ draggedEvent: null })
    this.moveEvent({ event, start, end })
  }

  moveEvent({ event, start, end, isAllDay: droppedOnAllDaySlot }) {
    const { events } = this.state

    const idx = events.indexOf(event)
    let allDay = event.allDay

    if (!event.allDay && droppedOnAllDaySlot) {
      allDay = true
    } else if (event.allDay && !droppedOnAllDaySlot) {
      allDay = false
    }

    const nextEvents = events.map(existingEvent => {
      return existingEvent.id == event.id
        ? { ...existingEvent, start, end }
        : existingEvent
    })

    this.setState({
      events: nextEvents,
    })


    // alert(`${event.title} was dropped onto ${updatedEvent.start}`)
  }

  resizeEvent = ({ event, start, end }) => {
    const { events } = this.state

    const nextEvents = events.map(existingEvent => {
      return existingEvent.id == event.id
        ? { ...existingEvent, start, end }
        : existingEvent
    })

    this.setState({
      events: nextEvents,
    })

    //alert(`${event.title} was resized to ${start}-${end}`)
  }

  newEvent(event) {
    // let idList = this.state.events.map(a => a.id)
    // let newId = Math.max(...idList) + 1
    // let hour = {
    //   id: newId,
    //   title: 'New Event',
    //   allDay: event.slots.length == 1,
    //   start: event.start,
    //   end: event.end,
    // }
    // this.setState({
    //   events: this.state.events.concat([hour]),
    // })
  }

  render() {
    return (
      <DragAndDropCalendar
        selectable
        localizer={this.state.localizer}
        events={this.state.events}
        onEventDrop={this.moveEvent}
        resizable

        onEventResize={this.resizeEvent}
        onSelectSlot={this.newEvent}
        onDragStart={console.log}
        defaultView={Views.DAY}
        defaultDate={now}
        views={['week', 'day' ]}
        dragFromOutsideItem={
          this.state.displayDragItemInCell ? this.dragFromOutsideItem : null
        }
        onDropFromOutside={this.onDropFromOutside}
        handleDragStart={this.handleDragStart}
        messages = {{
          allDay: 'journée',
          previous: 'précédent',
          next: 'suivant',
          today: 'aujourd\'hui',
          month: 'mois',
          week: 'semaine',
          day: 'jour',
          agenda: 'Agenda',
          date: 'date',
          time: 'heure',
          event: 'événement', // Or anything you want
          showMore: total => `+ ${total} événement(s) supplémentaire(s)`
        }}
      />
    )
  }
}

export default Dnd