import React, {Component, Fragment} from 'react';
import { store } from 'react-notifications-component';

import {
    Row, Col, ListGroupItem, ListGroup,
    Button, CardTitle, CustomInput,Nav,NavItem,
    NavLink,Dropdown,DropdownMenu,DropdownToggle,
    UncontrolledButtonDropdown,Modal,ModalHeader, ModalBody, ModalFooter

} from 'reactstrap';
import {
    Sparklines,
    SparklinesCurve,
    SparklinesReferenceLine,
    SparklinesSpots
} from 'react-sparklines';

import Circle from 'react-circle';
import { connect } from 'react-redux';
import { userActions,monitoringActions } from '../../../_actions';

import {VerticalTimeline, VerticalTimelineElement} from 'react-vertical-timeline-component';
import socketIOClient from "socket.io-client";

import {
    faTrashAlt,
    faCheck,
    faFilePdf,
    faFileExcel,
    faFileArchive,
    faFileAlt,
    faCloudDownloadAlt

} from '@fortawesome/free-solid-svg-icons';



import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import { L } from 'react-ladda/dist/constants';

function boxMullerRandom() {
    let phase = false,
        x1, x2, w, z;

    return (function () {

        if (phase = !phase) {
            do {
                x1 = 2.0 * Math.random() - 1.0;
                x2 = 2.0 * Math.random() - 1.0;
                w = x1 * x1 + x2 * x2;
            } while (w >= 1.0);

            w = Math.sqrt((-2.0 * Math.log(w)) / w);
            return x1 * w;
        } else {
            return x2 * w;
        }
    })();
}

function randomData(n = 30) {
    return Array.apply(0, Array(n)).map(boxMullerRandom);
}

const sampleData = randomData(30);
const ENDPOINT = "https://d2sapi.opentlv.com:6001";

class InfosPlayer extends React.Component {
    constructor(props) {
        super(props);

        this.toggle = this.toggle.bind(this);
        this.parserResponse = this.parserResponse.bind(this);
       
        this.screenshot = this.screenshot.bind(this);
    
        this.state = {
            dropdownOpen: false,
            versionSoft:null,
            responseSocket:{
                getPlatformInfo:null,
                getStorageInfo:null,
                getUsageData:null,
                initNetworkInfoIp:null,
                initNetworkInfoMac:null,
                getTemp:null,
              getCpuMem:null,
            getCurrentTime:null,
            getTimeZone:null,
            versionSoft:null,
            getSystemMonitoringInfo:null,
            },
            modal: false,
            isPlaying:null

        };
        console.log(this.props)
        console.log(this.props.playerId)
        console.log(this.props.playerId)
        console.log(this.props.playerId)

    }

    toggle(id) {
        console.log("fdsfdsfsdfdsfsdds")
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));

        
    }
    screenshot(data) {
        this.setState({
          isPlaying: data,
          modal: !this.state.modal
        });
      }


    doAction(action,playerID){
        console.log("action");
        console.log(action);
        var data={
            command : action,
            player_id : playerID
    
          };
          console.log(data)
        const socket = socketIOClient(ENDPOINT);
        socket.on("connect", () => {
        socket.emit('call', data)
    
        })
    }
    
    parserResponse(type,data){
        console.log("parserResponse")
    console.log(type)
        console.log(data)
        const category = {...this.state[this.responseSocket]};

        switch(type){
            case "initNetworkInfoIp":
         //       category[type] =  data.ipAddress;
       //      this.setState({ responseSocket = this.data});
             this.setState({ 
                ...this.state.responseSocket[type] = data.ipAddress 
             
            });
            return
            case "initNetworkInfoMac":
                this.setState({ 
                    ...this.state.responseSocket[type] = data.macAddress 
                 
                });
                return
            case "versionSoft":                         
            this.setState({ 
                ...this.state.responseSocket[type] = data.version
             
            });
            return 
            case "getPlatformInfo":
                this.setState({ 
                    ...this.state.responseSocket[type] = data.firmwareVersion
                 
                });
                return
                case "getSystemMonitoringInfo":
                    console.log(data)
                    console.log(category)

                    if (data.message != ""){

                    this.setState({ 
                        ...this.state.responseSocket[type] = data.message
                     
                    });
                }
                    return
                    case "getTemp":
                        console.log(data)
                        console.log(category)
                        this.setState({ 
                            ...this.state.responseSocket[type] = data.value
                         
                        });
                    return 
                    case "getUsageData":

                        this.setState({ 
                            ...this.state.responseSocket[type] = data.uptime
                         
                        });
                  

                    return

                    case "getTimeZone":
                        this.setState({ 
                            ...this.state.responseSocket[type] = data.timeZone['continent']
                         
                        });
                  

                    return
                    case 'captureScreen':
                    store.addNotification({
                        title: "[Lecture en cours]",
                        message:data.message,
                        type: "success",
                        insert: "top",
                        container: "top-center",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                          duration: 10000,
                          onScreen: true
                        }
                      });
                      return 
            case "getStorageInfo":

               // (used*100/total)
               
               var disk  = data.used*100/data.total
                this.setState({ 
                  ...this.state.responseSocket[type] = disk.toFixed(2) 
                });
                return
                case 'isPlaying':
                 
                    this.screenshot(data)
                    
                return

        }
    //   console.log(category);
        //category[type] =  data;
        //category[type] = 
        
  /*      if (typeof key === 'string') {
    category[key] = val;
  } else {
    key.map((item, index) => {
      category[item] = val[index];
    });
  }*/
  //this.setState({[cat]: category});
    //    this.responseSocket[type] = this.data;
    
      // this.setState({ responseSocket = this.data});
      
      

    }
    getinfosPlayer(id){
        console.log("infos test")
        const socket = socketIOClient(ENDPOINT);
      // id = 5

        //add from database later
        socket.on("connect", () => {
            var  commands = ["getPlatformInfo","getStorageInfo","getUsageData","initNetworkInfoIp","initNetworkInfoMac",
            "getTemp","getCpuMem","getCurrentTime","getTimeZone","versionSoft","getSystemMonitoringInfo"] 
    
            socket.emit('subscribe-to-channel', 'player'+ id)
            socket.emit('subscribe-to-channel', 'player'+ id + 'data')
           // commands = ['bersionSoft']

           commands.map(command => {

           const data={
                command :command,
                player_id : id
                };        
            socket.emit('call' ,data);

        });

         //   socket.emit('reloadMedialist',playlistID);

        });

        socket.on('responseplayer' ,  (data) => {
            this.parserResponse(data.command,data.data)
          });
    }

    componentDidMount() {
        this.getinfosPlayer(this.props.playerId);
    }
    render() {
        const {
            responseSocket

        } = this.state;

        console.log(responseSocket)
        return (
            <Fragment>
                <h3 className="drawer-heading">
                    Player ID </h3>
                    <CardTitle className="text-center">Live Statistics</CardTitle>


                <div className="drawer-section">
                    <Row>
                        <Col>
                            <div className="progress-box">
                                <h4>Disque </h4>
                                <Circle
                                    animate={true} // Boolean: Animated/Static progress
                                    animationDuration="8s" // String: Length of animation
                                    responsive={true} // Boolean: Make SVG adapt to parent size
                                    size="80" // String: Defines the size of the circle.
                                    lineWidth="25" // String: Defines the thickness of the circle's stroke.
                                    progress={!responseSocket["getStorageInfo"] ? "Chargement"  : this.state.responseSocket["getStorageInfo"] } // String: Update to change the progress and percentage.
                                    progressColor="var(--warning)" // String: Color of "progress" portion of circle.
                                    bgColor="#f3f5f2" // String: Color of "empty" portion of circle.
                                    textColor="#bcbebf" // String: Color of percentage text color.
                                    /* textStyle={{
                                         font: 'bold 4rem Helvetica, Arial, sans-serif' // CSSProperties: Custom styling for percentage.
                                     }}*/
                                    percentSpacing={10} // Number: Adjust spacing of "%" symbol and number.
                                    roundedStroke={true} // Boolean: Rounded/Flat line ends
                                    showPercentage={true} // Boolean: Show/hide percentage.
                                    showPercentageSymbol={true} // Boolean: Show/hide only the "%" symbol.
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="progress-box">
                                <h4>Température</h4>
                                <Circle
                                    animate={true} // Boolean: Animated/Static progress
                                    animationDuration="2s" // String: Length of animation
                                    responsive={true} // Boolean: Make SVG adapt to parent size
                                    size="50" // String: Defines the size of the circle.
                                    lineWidth="25" // String: Defines the thickness of the circle's stroke.
                                    progress={!responseSocket["getTemp"] ? "Chargement"  : this.state.responseSocket["getTemp"] } // String: Update to change the progress and percentage.
                                    progressColor="var(--success)" // String: Color of "progress" portion of circle.
                                    bgColor="#f3f5f2" // String: Color of "empty" portion of circle.
                                    textColor="#bcbebf" // String: Color of percentage text color.
                                    /* textStyle={{
                                         font: 'bold 4rem Helvetica, Arial, sans-serif' // CSSProperties: Custom styling for percentage.
                                     }}*/
                                    percentSpacing={10} // Number: Adjust spacing of "%" symbol and number.
                                    roundedStroke={true} // Boolean: Rounded/Flat line ends
                                    showPercentage={true} // Boolean: Show/hide percentage.
                                    showPercentageSymbol={false} // Boolean: Show/hide only the "%" symbol.
                                />
                            </div>
                        </Col>
                        <Col>
                            <div className="progress-box">
                                <h4>CPU</h4>
                                <Circle
                                    animate={true} // Boolean: Animated/Static progress
                                    animationDuration="4s" // String: Length of animation
                                    responsive={true} // Boolean: Make SVG adapt to parent size
                                    size="80" // String: Defines the size of the circle.
                                    lineWidth="25" // String: Defines the thickness of the circle's stroke.
                                    progress={!responseSocket["getCpuMem"] ? "???"  : this.state.responseSocket["getCpuMem"] } // String: Update to change the progress and percentage.
                                    progressColor="var(--danger)" // String: Color of "progress" portion of circle.
                                    bgColor="#f3f5f2" // String: Color of "empty" portion of circle.
                                    textColor="#bcbebf" // String: Color of percentage text color.
                                    /* textStyle={{
                                         font: 'bold 4rem Helvetica, Arial, sans-serif' // CSSProperties: Custom styling for percentage.
                                     }}*/
                                    percentSpacing={10} // Number: Adjust spacing of "%" symbol and number.
                                    roundedStroke={true} // Boolean: Rounded/Flat line ends
                                    showPercentage={true} // Boolean: Show/hide percentage.
                                    showPercentageSymbol={true} // Boolean: Show/hide only the "%" symbol.
                                />
                            </div>
                        </Col>
                    </Row>
                    <div className="divider"/>
                    <div className="mt-3">
                        <CardTitle className="text-center">Infos Player</CardTitle>
                        {this.state.versionSoft &&
                    <>
            <InfosPlayer playerId={this.state.playerId}/>
                 
                    </>
                    }          
                        
                        
                   
                    </div>
                </div>
                <h3 className="drawer-heading">
                 Adresse IP : {!responseSocket["initNetworkInfoIp"] ? "Chargement en cours"  : this.state.responseSocket["initNetworkInfoIp"] }
                </h3>

                <h3 className="drawer-heading">
                    Mac : {!responseSocket["initNetworkInfoMac"] ? "Chargement en cours"  : this.state.responseSocket["initNetworkInfoMac"] }
                </h3>
                <h3 className="drawer-heading">
                    Version application :  {!responseSocket["versionSoft"] ? "Chargement en cours"  : this.state.responseSocket["versionSoft"] }
                </h3>
                <h3 className="drawer-heading">
                    Version Firmware :  {!responseSocket["getPlatformInfo"] ? "Chargement en cours"  : this.state.responseSocket["getPlatformInfo"] }
                </h3>
                <h3 className="drawer-heading">
                    Uptime :  {!responseSocket["getUsageData"] ? "Chargement en cours"  : this.state.responseSocket["getUsageData"] }
                </h3>
                <h3 className="drawer-heading">
                    TimeZone :  {!responseSocket["getTimeZone"] ? "Chargement en cours"  : this.state.responseSocket["getTimeZone"] }
                </h3>


                
                <ListGroup flush>

                                        <ListGroupItem color="primary">
                                            <div className="widget-content p-0">
                                                <div className="widget-content-wrapper">
                                                    <div className="widget-content-left mr-3">
                                                        <div className="icon-wrapper m-0">
                                                            <b className="text-primary">
                                                      
                                                            </b>
                                                        </div>
                                                    </div>
                                                    <div className="widget-content-left">
                                                        <div className="widget-heading text-dark">
                                                            Actions Remote
                                                        </div>
                                                        <div className="widget-subheading opacity-10">
                                                   
                                                        </div>
                                                    </div>
                                                    <div className="widget-content-right">
                                                        <div className="icon-wrapper m-0">
                                                            <div className="progress-circle-wrapper">
                                                                
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </ListGroupItem>
                                        <ListGroupItem className="p-0">
                                            <div className="grid-menu grid-menu-2col">
                                                <Row className="no-gutters">
                                                    <Col sm="6">
                                                    <UncontrolledButtonDropdown>
                                                    <DropdownToggle caret
                                                            className="btn-icon-vertical btn-square btn-transition"
                                                            outline color="link">
                                                            <i className="lnr-license btn-icon-wrapper btn-icon-lg mb-3"> </i>
                                                            Application (Player)
                                                        </DropdownToggle>
                                          
                                            <DropdownMenu>
                                                <Nav vertical>
                                                    <NavItem className="nav-item-header">
                                                        Activity
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink>
                                                        <Button   onClick={() => this.doAction("restart",this.props.playerId)}  className="mb-2 mr-2" color="warning">Restart app</Button>
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink>
                                                        <Button   onClick={() => this.doAction("captureScreen",this.props.playerId)}  className="mb-2 mr-2" color="warning">Lecture en cours</Button>
                                                        </NavLink>
                                                    </NavItem>
                                                    <NavItem>
                                                        <NavLink>
                                                        <Button className="mb-2 mr-2" color="warning" onClick={() => this.doAction("isPlaying",this.props.playerId)}  >Screenshot</Button>
                                                        </NavLink>
                                                    </NavItem>
                                                
                                                 
                                                </Nav>
                                            </DropdownMenu>
                                        </UncontrolledButtonDropdown>
                                                 
                                                    </Col>
                                                    
                                                </Row>
                                            </div>
                                        </ListGroupItem>
                                    </ListGroup>
                                    <Modal isOpen={this.state.modal} fade={false} toggle={this.screenshot} className={this.props.className}>
          <ModalHeader toggle={this.screenshot}>Screenshot</ModalHeader>
          <ModalBody>
          {this.state.isPlaying &&
                    <>
                    <img src={`${this.state.isPlaying}`} />

          </>
                    }          
                        
          </ModalBody>
        </Modal>
            </Fragment>
        )
    }
}

/*function mapState(state) {
    const { users, authentication, enseignes } = state;
    const { user } = authentication;
    return { user, users, enseignes };
    }
    
    const actionCreators = {
    //tmp before 1.2.9

        getinfosPlayer: monitoringActions.getInfosPlayer,
    }
    
    //const connectedInterfacePage = connect(mapState, actionCreators)(Interface);
    //export default connect(mapState, actionCreators)(Interface);
    const connectedInfosPlayer = connect(mapState, actionCreators)(InfosPlayer);
    export { connectedInfosPlayer as InfosPlayer };*/
export default InfosPlayer;