import React, {Suspense, lazy, Fragment,useState} from 'react';
import {connect} from 'react-redux';
import cx from 'classnames';

import ResizeDetector from 'react-resize-detector';
import { Router, Route, Switch, Redirect,withRouter } from 'react-router-dom';

import { history } from '../_helpers';
import { alertActions } from '../_actions';
import { PrivateRoute } from '../_components';
import {LoginPage}  from '../LoginPage';
import {Main} from '../Main';
import detectBrowserLanguage from 'detect-browser-language'
import { IntlProvider } from 'react-intl';
import messages from '../_i18n/messages';

import './App.css';
import Dashboard from '../Dashboard';
import ReactNotification from 'react-notifications-component'
import 'react-notifications-component/dist/theme.css'

function App() {
  const [locale, setLocale] = useState(detectBrowserLanguage().substr(detectBrowserLanguage().length - 2));

  return (
    <div>
      <IntlProvider locale={locale} messages={messages[locale]}>
      <ReactNotification />

      <Router history={history}>
        <Switch>
          <PrivateRoute path="/dashboard" component={Main} locale={locale}/>
          <Route  path="/login" component={LoginPage} locale={locale}/>
          <Route  path="/" component={LoginPage} locale={locale}/>

        </Switch>
      </Router>
      </IntlProvider>
    </div>
  );
}

export default App;
