import {BrowserRouter as Router, Route, Redirect} from 'react-router-dom';
import React, {Suspense, lazy, Fragment} from 'react';
import Loader from 'react-loaders'

import {
    ToastContainer,
} from 'react-toastify';

const Dashboard = lazy(() => import('../../Dashboard'));

const AppMain = () => {

    return (
        <Fragment>

            {/* Components */}

         
            {/* Dashboards */}

            <Suspense fallback={
                <div className="loader-container">
                    <div className="loader-container-inner">
                        <div className="text-center">
                            <Loader type="ball-grid-beat"/>
                        </div>
                        <h6 className="mt-3">
                            Please wait while we load all the Dashboard
                        </h6>
                    </div>
                </div>
            }>
                <Route path="/dashboard" component={Dashboard}/>
            </Suspense>
         
            <ToastContainer/>
        </Fragment>
    )
};

export default AppMain;