import React, {Fragment, Component, useRef, useState,useReducer,setState} from "react";
import Stikky from "react-stikky"

const style = {
  border: '1px dashed gray',
  backgroundColor: 'white',
  padding: '0.5rem 1rem',
  marginRight: '1.5rem',
  marginBottom: '1.5rem',
  cursor: 'move',
 
  float: 'left',
}


const VideoMp = ({ idMedia,idCatalog,mediaName,closeVideo}) => {
  console.log("VIDEO");
 
  console.log(closeVideo);
var fileExtension = mediaName.split('.').pop(); //"pdf"
let url = "https://d2sapi.opentlv.com/media-data/" + idCatalog + "/" + idMedia;


//let url = "https://test-videos.co.uk/vids/bigbuckbunny/mp4/h264/1080/Big_Buck_Bunny_1080_10s_1MB.mp4";
console.log(url);
console.log(fileExtension);

    if(fileExtension == "jpg" || fileExtension == "png"){

      return (
        <div>
         <i className="dropdown-icon lnr-file-empty" onClick={() => closeVideo(false)}> Fermer</i>
    
        <Stikky edge="top" triggerDistance="50" zIndex="999" style={{background:"#f8f9fa"}}>
    
    
        <img width="600" heigth="600" id="videoMp" autoPlay muted
        controls 
        src={url}></img>
        </Stikky> 
        </div>
    
      );

    }
   if(fileExtension == "mp4"){
    
  return (
    <div>
     <i className="dropdown-icon lnr-file-empty" onClick={() => closeVideo(false)}> Fermer</i>

    <Stikky edge="top" triggerDistance="50" zIndex="999" style={{background:"#f8f9fa"}}>


    <video width="600" heigth="600" id="videoMp" autoPlay muted
    controls 
    src={url}></video>
    </Stikky> 
    </div>

  );
    
    }

};
export default VideoMp
