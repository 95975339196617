import React, {Fragment, Component, useRef} from "react";
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import {Progress} from 'react-sweet-progress';
import CountUp from 'react-countup';
import {AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio} from 'availity-reactstrap-validation';

import {
    Col, Row, Button,
    Card, Nav, NavLink, NavItem,Collapse,
    DropdownMenu, DropdownItem,ListGroup, ListGroupItem,
    CardBody,CustomInput, InputGroup, InputGroupAddon, Input,
    Table, UncontrolledButtonDropdown, DropdownToggle,CardTitle,
    ButtonGroup,Form, FormGroup, Label,FormText, Container,
    Badge,Modal,ModalBody,ModalFooter,ModalHeader
    
} from 'reactstrap';
import ReactTable  from  'react-table'; 
import {DataTable} from './Components/Datatable'
import JwPagination from 'jw-react-pagination';

import Hamburger from 'react-hamburgers';
import cx from 'classnames';
import { connect } from 'react-redux';
import {makeData} from "./utils";

import PageTitle from '../../../Layout/AppMain/PageTitle';
import {Campagnes} from './Campagnes';
import Media from './Media';
import Campagne from './Campagne';
import FormCampagne from './FormCampagne';
import FormMedia from './FormMedia';
import VideoMp from './Components/VideoMp'

import { interfaceActions } from '../../../_actions';
import { DndProvider } from 'react-dnd'
import Backend from 'react-dnd-html5-backend'
import {alertActions} from '../../../_actions/alert.actions'
import {FormEditCampagne} from './FormEditCampagne'

import {
    faStar,
    faCalendarAlt,
    faAngleLeft,
    faAngleDown,
    faSearch,
    faAngleRight,
    faAngleDoubleLeft,
    faAngleDoubleRight,
    faTags

} from '@fortawesome/free-solid-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ItemTypes from "./ItemTypes";
import DragAndDropCalendar from "./Components/DragAndDropCalendar"
import { eventsPlaylist } from "../../../_reducers/eventsPlaylist.reducer";
import socketIOClient from "socket.io-client";
import { interfaceConstants } from "../../../_constants";

const customLabels = {
    first: <FontAwesomeIcon icon={faAngleDoubleLeft}/>,
    last: <FontAwesomeIcon icon={faAngleDoubleRight}/>,
    previous: <FontAwesomeIcon icon={faAngleLeft}/>,
    next: <FontAwesomeIcon icon={faAngleRight}/>
}
const ENDPOINT = "https://d2sapi.opentlv.com:6001";

class Support extends Component {
  
    constructor(props) {
        super(props);

        this.onChangePage = this.onChangePage.bind(this);
        this.playVideo = this.playVideo.bind(this);
        console.log("PROPS");
        console.log(this.props);
        this.state = {
            targetbox: null,
            pageOfItems: [],
            active: false,
            date: new Date(),
            submitted: false,
            isOpenModal: false,
            datefrom: new Date(),
            dateto: new Date(),
            timefrom: new Date(),
            timeto: new Date(),
            visible: true,
            isShowingMore: false,
            visibleFormCampagne: false,
            visiblePlaylist: false,
            visibleFormMedia: false,
            popoverOpen1: false,
            seriesRadial: [76],
            campagnes: [],
            assigned: [],
            test:[],
            playerVisible : false,
            renderVideo: false,
            events:[],
            dataFormUpddatePlaylist:[],
            medias: {
                data: [],
                meta: {
                  current_page: 1,
                  from: 1,
                  last_page: 1,
                  per_page: 5,
                  to: 1,
                  total: 1,
                },
            },

        };



        this.toggleCampagnes = this.toggleCampagnes.bind(this);
        this.toggleMedias = this.toggleMedias.bind(this);
        this.togglePlaylist = this.togglePlaylist.bind(this);
        this.showModal =  this.showModal.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
        this.onDropMedia = this.onDropMedia.bind(this);
        this.closeVideo = this.closeVideo.bind(this);
        this.toggleShowMore = this.toggleShowMore.bind(this);
        this.unAffiliate = this.unAffiliate.bind(this);
        this.updatePlaylist = this.updatePlaylist.bind(this);
        this.changeDuration = this.changeDuration.bind(this);
        this.handleChangeForm = this.handleChangeForm.bind(this);

    }
    componentDidMount() {

      this.props.getCampagnes(this.props.match.params.id);    
      this.props.getSecteurs(this.props.match.params.id);
    //  this.props.getPdv(this.props.match.params.id);
      this.props.getMedias(this.props.match.params.id);
      this.props.getEventsPlaylist(this.props.match.params.id);
      this.props.getCanaux(this.props.match.params.id);
      this.props.getpdvClient(this.props.match.params.id);
      

    
}
 handleChangeForm(e)  {
    // console.log(event.target.name)
    // console.log(event.target.value)
    console.log(e.target.name);

   // const index = e.target.selectedIndex;
    //const optionElement = e.target.childNodes[index];
    //const optionElementId = optionElement.getAttribute('id');
    let id = Array.from(e.target.selectedOptions, option => option.id );
    
    this.setState({ dataFormUpddatePlaylist:id })

  
  }
 loadEnseigne(){


  }
 
  onChangePage(pageOfItems) {
    this.setState({pageOfItems});
}
playVideo(id_catalog,idMedia,mediaName){
    console.log("playvideo INDEX ");    
    this.setState({ idCatalog: id_catalog })
    this.setState({ idMedia: idMedia })
    this.setState({ mediaName: mediaName })

    this.setState({ renderVideo: true })

 //  this.props.playVideo(id);

}
closeVideo(){

    this.setState({ renderVideo: false })

}

onDrop(files) {
    this.setState({files});
}
onDropMedia(index,item) {
    this.props.alertSuccess("Media affilié.");
    var property="campagne"+index;

    var updates = {};
  
    if (this.state.assigned[property]){
             //updates.push(item);
        let assigned = [...this.state.assigned[property]]
         assigned.push(item)         
        this.setState({
            ...this.state.assigned[property] = [assigned] 
        });
    }else{
        updates = item
        
        this.setState({ 
            ...this.state.assigned[property] = [updates] 
         
        });
    }

}

toggleShowMore()  {

    this.setState({
        isShowingMore: !this.state.isShowingMore
    });
    
}

changeDuration(){


}
updatePlaylist(){
    console.log('this.props.dataFormUpddatePlaylist')

    console.log(this.state.dataFormUpddatePlaylist)
    this.props.updatePlaylist(this.props.match.params.id,this.state.dataFormUpddatePlaylist);
}
unAffiliate(id){
    this.props.unAffiliate(id);


}
showModal(){


    this.setState({
        isOpenModal: !this.state.isOpenModal
    });

}
onCancel() {
    this.setState({
        files: []
    });
}

submit = values => {
    // print the form values to the console
    console.log(values)
  }

toggleCampagnes() {
    this.setState({
        visibleFormCampagne: !this.state.visibleFormCampagne
    });
}
toggleMedias() {
    this.setState({
        visibleFormMedia: !this.state.visibleFormMedia
    });
}
togglePlaylist() {
    this.setState({
        visiblePlaylist: !this.state.visiblePlaylist
    });
}

    onDismiss() {
        this.setState({visible: false});
    }


    render() {

        const { alert , campagnes, enseigne, pdvs, secteurs, medias, 
            visibleFormCampagne , visibleFormMedia, data, assigned, 
            eventsPlaylist, isShowingMore,date,name,canaux,pdvclient
        } = this.props;

        console.log("ENSEIGNE");

        const breads = [{ name: 'interface',href:'interface' },{ name: 'enseigne',href:'interface' },{name:this.props.location.state.name,href:""}];

        const columns = ['Move', 'id', 'name', 'created_at','langue','actions'];


        return (
                   
            <Fragment>
               
            <DndProvider backend={Backend}>
            
            <ReactCSSTransitionGroup
                component="div"
                transitionName="TabsAnimation"
                transitionAppear={true}
                transitionAppearTimeout={0}
                transitionEnter={false}
                transitionLeave={false}>
                <div>
                    <div
                        className={cx("app-inner-layout", {
                            'open-mobile-menu': this.state.active,
                        })}
                            
                    >
                        <div className="app-inner-layout__header bg-heavy-rain">
                            <PageTitle
                                heading={enseigne.items && 
                                enseigne.items.name
                                } 
                                subheading="gestion de vos contenus"
                                icon="pe-7s-power icon-gradient bg-mixed-hopes"
                                breads={breads}
                            />
                        </div>
                        <div className="app-inner-layout__wrapper">
                            <Card className="app-inner-layout__content">

                            <Nav vertical>
                            {!this.state.renderVideo ?  "" :  <VideoMp idMedia={this.state.idMedia} idCatalog={this.state.idCatalog} mediaName={this.state.mediaName} closeVideo={this.closeVideo} /> }

                            <NavItem className="pt-4 pl-3 pr-3 pb-3">
                            <InputGroup>
                                
                                {date}

                            </InputGroup>
                        </NavItem>
                            </Nav>           

                                            <button color="secondary" className="mb-2 mr-2 btn-icon-vertical btn-secondary"  onClick={this.toggleMedias}>
                                                <i className="pe-7s-tools btn-icon-wrapper"> </i>
                                                Ajout Media
                                            </button>
                        <Collapse isOpen={this.state.visibleFormMedia} toggle={this.state.toggleMedias} >
                            <Card>
                            <CardBody>
                            {secteurs && 
                                <FormMedia secteurs={secteurs}  formMedia={this.props.addMedia} enseigne_id={this.props.match.params.id}/>
                                }
                            </CardBody>
                            </Card>
                        </Collapse>           
                        
                            <NavItem className="headbar bg-dark ">
                                Medias disponibles
                            </NavItem>
                                    {medias.medias &&
                                    <DataTable url="/api/users" id={this.props.match.params.id} columns={columns}  playVideo={this.playVideo}/>
                                    }

                                   <div style={{ overflow: 'hidden', clear: 'both' }}>
                                   
      </div>
                            </Card>
                            <Card className="app-inner-layout__sidebar">
                                <Nav vertical id="nav" >
                                <NavItem className="pt-4 pl-3 pr-3 pb-3">
                                                <InputGroup>
                                         
                                                    
                                                </InputGroup>
                                            </NavItem>
                                    <NavItem className="pt-4 pl-3 pr-3 pb-3">
                                    <Button className="mb-2 mr-2 btn-icon-vertical" onClick={this.togglePlaylist} style={{ marginBottom: '1rem' }}>
                                    <i className="pe-7s-tools btn-icon-wrapper"> </i>
                                   
                                        Playlist </Button>
                        <Collapse isOpen={this.state.visiblePlaylist} toggle={this.state.togglePlaylist} >
                            <Card>
                            <CardBody>
                            {eventsPlaylist.eventsPlaylist && 
                           <DragAndDropCalendar eventsPlaylist={eventsPlaylist.eventsPlaylist}  />
                            }
                            </CardBody>
                            </Card>
                        </Collapse>


                                    <Button className="mb-2 mr-2 btn-icon-vertical" onClick={this.toggleCampagnes} style={{ marginBottom: '1rem' }}>
                                    <i className="pe-7s-tools btn-icon-wrapper"> </i>

                                        Ajout Campagne</Button>

                        <Collapse isOpen={this.state.visibleFormCampagne} toggle={this.state.toggleCampagnes} >
                            <Card>
                            <CardBody>
                            {pdvs && 
                                <FormCampagne pdvs={pdvs}  
                                form={this.props.addCampagne}
                                edit={this.props.editCampagne}
                                enseigne_id={this.props.match.params.id}
                              />
                                }
                            </CardBody>
                            </Card>
                        </Collapse>


                        <Modal isOpen={this.state.isOpenModal}  toggle={() => this.setState({ isOpenModal: false}) }>
<AvForm>
  <ModalHeader >Update playlist campagne</ModalHeader>
  <ModalBody>
  <FormGroup>
    <Label for="exampleSelect">
      Selection du canal
    </Label>
    <AvField type="select" name="canaux" id="canaux"  multiple  onChange={this.handleChangeForm}  required  value={this.state.dataFormUpddatePlaylist.value}> 
                            {canaux.items && 
                                    canaux.items.map((item) => <option id={item.id} key={item.id}>{item.name}</option>)}        
    </AvField>
    </FormGroup>
  </ModalBody>
  <ModalFooter>
  <Button color="primary"  onClick={this.updatePlaylist}> Valider</Button>
   <Button color="secondary"  onClick={() => this.setState({ isOpenModal: false})}>Annuler</Button>
  </ModalFooter>
  </AvForm>
 </Modal>
                        <Button className="mb-2 mr-2 btn-icon-vertical" onClick={this.showModal}  style={{ marginBottom: '1rem' }}>
                                    <i className="pe-7s-tools btn-icon-wrapper"> </i>

                                        Validation Playlist </Button>

                                   
                                    
                                    </NavItem>
                                    <NavItem className="headbar bg-dark ">
                                        <h4>Campagnes</h4>
                                    </NavItem>
                                    <NavItem>

                                    <div className="campagnes" id="nav-campagne">


                                    {medias.medias && campagnes.campagnes &&
                                        <>
                                        {campagnes.campagnes.map((campagne, index) =>
                                        <Campagne  accept={ItemTypes} 
                                        campagne={campagne}
                                        props={this.props} 
                                        enseigne_id={this.props.match.params.id} 
                                        key={campagne.id} 
                                        state={this.state}
                                        toggleShowMore={this.toggleShowMore}
                                        isShowingMore={isShowingMore}
                                        canaux={canaux}
                                        pdvs={pdvs}
                                        pdvclient={pdvclient}
                                        />)}
                                        </>
                                        }          

                                    </div>
                                    </NavItem>
                                    
                                </Nav>
                            </Card>
                        </div>
                    </div>
                 
                </div>
            </ReactCSSTransitionGroup>
            </DndProvider>
        </Fragment>
        )
    }

_onAction(e) {
  console.log('user did something', e)
}

_onActive(e) {
  console.log('user is active', e)
  console.log('time remaining', this.idleTimer.getRemainingTime())
}

_onIdle(e) {
  console.log('user is idle', e)
  console.log('last active', this.idleTimer.getLastActiveTime())
}
}


function mapState(state) {
const { users, authentication, campagnes, enseignes, enseigne, pdvs, secteurs, medias, alert, assigned, eventsPlaylist,canaux,pdvclient} = state;
const { user } = authentication;

return { user, users, campagnes, enseignes, enseigne, pdvs, secteurs, medias,alert,assigned, eventsPlaylist,canaux,pdvclient };
}

const actionCreators = {
    getCampagnes: interfaceActions.getCampagnes,
    getSecteurs: interfaceActions.getSecteurs,
    //getEnseigne: interfaceActions.getEnseigne,
    getPdv: interfaceActions.getPdv,
    addCampagne: interfaceActions.addCampagne,
    editCampagne:interfaceActions.getCampagne,
    addMedia: interfaceActions.addMedia,
    getMedias: interfaceActions.getMedias,
    getEventsPlaylist: interfaceActions.getEventsPlaylist,
    alertSuccess : alertActions.success,
    affiliateMedia: interfaceActions.affiliateMedia,
    unAffiliate: interfaceActions.unAffiliate,
    deleteCampagne: interfaceActions.deleteCampagne,
    updatePlaylist: interfaceActions.updatePlaylist,
    getCanaux:interfaceActions.getCanaux,
    getpdvClient:interfaceActions.getpdvClient,
    
}

//const connectedInterfacePage = connect(mapState, actionCreators)(Interface);
//export default connect(mapState, actionCreators)(Interface);
const connectedSupport = connect(mapState, actionCreators)(Support);
export { connectedSupport as Support };

