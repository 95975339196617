import React, {Fragment, Component, useRef, useState,useReducer} from "react";
import { useDrop } from 'react-dnd'
import { connect } from 'react-redux';
import { createStore } from 'redux'
import { authHeader } from '../../../_helpers';
import {AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio} from 'availity-reactstrap-validation';

import {
    Col, Row, Button,
    Card, Nav, NavLink, NavItem,
    DropdownMenu, DropdownItem,ListGroup, ListGroupItem,CardHeader, 
    CardBody,CustomInput, InputGroup, InputGroupAddon, Input,
    Table, UncontrolledButtonDropdown, DropdownToggle,CardTitle,
    ButtonGroup,Form, FormGroup, Label,FormText, Container,CardFooter,
    Badge,Modal,ModalBody,ModalFooter,ModalHeader
} from 'reactstrap';
import { interfaceActions } from '../../../_actions';
import {alertActions} from '../../../_actions/alert.actions'
import {store}  from '../../../_helpers/store';


const style = {
  backgroundColor: 'white',

}

const Campagne = ({ status, campagne, props ,enseigne_id,canaux,pdvs,pdvclient }) => {

  const ref = useRef(null);
  const [medias, setCount] = useState(props.medias.medias.data);
  let value = 'campagne'+campagne.id
  const [assigned, setAssigned] = useState(props.assigned[value]);
  const [isShowingMore, toggleShowMore] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [duration, setDuration] = useState(5);

  const [dataForm, setForm] = useState({
    duration: '',
    canaux : '',
    pdvs : [],

})

  const [data, setData] = useState({
    duration: '',
    submitted: '',
    mediaID : '',
    canal : '',

})


const handleChangeForm = (e) => {
  // console.log(event.target.name)
  // console.log(event.target.value)
  console.log(e.target);
  if (e.target.name == "canaux"){
      console.log("canaux");
      const index = e.target.selectedIndex;
      const optionElement = e.target.childNodes[index];
      const optionElementId = optionElement.getAttribute('id');

      setForm({
        ...dataForm,
        [e.target.name] : optionElementId
    })

//      console.log();
      props.getPdv(optionElementId);
  
  }
  else if (e.target.name == "pdvs"){
    const index = e.target.selectedIndex;
    const optionElement = e.target.childNodes[index];
    const optionElementId = optionElement.getAttribute('id');

    console.log(optionElementId)
      if(optionElementId == 'tpdvs'){
        setForm({
          ...dataForm,
          [e.target.name] : optionElementId 
      })

      }else{

        let id = Array.from(e.target.selectedOptions, option => option.id );
        setForm({
          ...dataForm,
          [e.target.name] : id 
      })
      }
    
  }
  else{
  setForm({
    ...dataForm,
    [e.target.name] : e.target.value
})
}
}
const [isOpen, setShow] = React.useState(false);
const [mediaId, setMediaId] = React.useState(false);
const [itemID, setItemId] = React.useState(false);

const [toggle, setOpen] = React.useState(false);
const handleChange = (e) => {
  // console.log(event.target.name)
  // console.log(event.target.value)


  setData({
    ...data,
    [e.target.name] : e.target.value
})
}
//const selectCanal: [],

const handleChangeCanaux = (e) => {
        console.log("change canal");
        console.log(e)
  let target = e.target
  let name = target.name
  //    let value = Array.from(target.selectedOptions, option => option.value);
   //   this.setState({
     // [name]: value
      //});
      console.log(e.target.language);
      console.log(e.target.id);
      
      console.log("event", e.target, e.target.selectedIndex);
      //this.setState({
        //  value: event.target.value,
          //id: event.target[event.target.selectedIndex].id
        //});
      this.setState({selectLanguage: e.target.value});
      this.setState({selectLanguageIds:e.target[e.target.selectedIndex].id});

      //let value = Array.from(event.target.selectLanguage, option => option.value );
      //let id = Array.from(event.target.selectLanguage, option => option.id );
      //this.setState({selectLanguage: value});
      //this.setState({selectLanguageIds: id});




}
const handleSubmitMedia = () => {

 
console.log("FORM AFFILIATE")
//console.log( dataForm.pdvs)
 props.affiliateMedia(dataForm.canaux, mediaId, campagne.id,dataForm.pdvs,enseigne_id);

  // props.affiliateMedia();


}
const handleSubmit = (event,mediaID,duration) => {
  
//console.log("mediaID")
//console.log(mediaID)
//onsole.log(event)
var data = {"mediaID":mediaID,"campagne_id":event,'duration':duration}
fetch('https://d2sapi.opentlv.com/interface/change-duration', {
    method: 'POST',
    headers: authHeader("multipart/form-data"),

    // We convert the React state to JSON and send it as the POST body
    body: JSON.stringify(data)
  }).then(function(response) {
    console.log(response)
    return response.json();
  });

}

 
//console.log('========================');
//console.log(props);
  

  const [{canDrop, isOver }, drop] = useDrop({
    accept: "card",
    drop(item) {
     // props.getCanaux(enseigne_id);
    //  console.log(item);
      
      setMediaId(item.id)
      setShow(true)

     // props.affiliateMedia(enseigne_id, item.id, campagne.id);
    },
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),

    })
  });
  drop(ref);

  const isActive = canDrop && isOver
  let backgroundColor = "white"
  if (isActive) {
    backgroundColor = '#495057'
  } else if (canDrop) {
    backgroundColor = '#d8f3e5'
  }
  return (
  <div ref={ref}>

<Modal isOpen={isOpen}  toggle={() => setShow(false)}>
<AvForm  onValidSubmit={handleSubmitMedia}>

  <ModalHeader isOpen={isOpen} toggle={() => setShow(false)}>Gestion campagne</ModalHeader>
  <ModalBody>

  {(() => {

let mediaName = medias.filter(media => media.id === mediaId);
if( mediaName[0]){
  return (
    <div>{mediaName[0].name}</div>
  )
  }

})()}
  <FormGroup>
    <Label for="exampleSelect">
      Selection du canal
    </Label>
    <AvField type="select" name="canaux" id="canaux" onChange={handleChangeForm}  required> 
                            <option> Veuillez choisir un canal</option>
                            {canaux.items && 
                                    canaux.items.map((item) => <option id={item.id} key={item.id}>{item.name}</option>)}
            
                            </AvField>
    
    </FormGroup>


      <div>
      {(() => {
      //  console.log(props.medias.medias.data)
       // console.log(mediaId)
        
              //  { props.medias.medias.data.map(function (media) {

        let mediaName = medias.filter(media => media.id === mediaId);
        if( mediaName[0]){
        //console.log(mediaName[0])
          {
        if (mediaName[0].name.split('.').pop() != "mp4"  ) {
          return (
            <div><FormGroup>
            <Label for="exampleSelect">
              Duration
            </Label>
            <AvField onChange={handleChangeForm}
              id="exampleSelect"
              name="select"
              type="input"
            > </AvField>  
            </FormGroup>
          </div>
          )
        }
      }
      }
            //    })}

      })()}
    </div>
    <FormGroup>
    <Label for="exampleSelect">
      Selection Site
    </Label>
    <AvField type="select" name="pdvs" id="pdvs" multiple onChange={handleChangeForm}  required  value={dataForm.pdvs.value}> 
    {pdvs.items && 

    <option id="tpdvs" key="tpdvs"> Tous points de vente</option>
    }

        {pdvs.items && 

          pdvs.items.map((item) => <option id={item.id} key={item.id}>{item.name}</option>)}

                    
                            </AvField>
    
    </FormGroup>
  
  
  </ModalBody>
  <ModalFooter>
  <Button color="primary"> Valider</Button>

   <Button color="secondary"  toggle={() => setShow(false)} >Annuler</Button>
  </ModalFooter>
  </AvForm>

 </Modal>
  <Card key={campagne.id} className="main-card pt-4 pl-3 pr-3 pb-3 nav-item campagne droppable" style={{ ...style, backgroundColor }}>
          <CardHeader>
              <i className="header-icon lnr-laptop-phone icon-gradient bg-plum-plate"> </i>
              {campagne.id} - {campagne.name}  
        
              <div className="btn-actions-pane-right actions-icon-btn">
                  <UncontrolledButtonDropdown>
                      <DropdownToggle className="btn-icon btn-icon-only" color="link">
                          <i className="pe-7s-menu btn-icon-wrapper"/>
                      </DropdownToggle>
                      <DropdownMenu className="dropdown-menu-shadow dropdown-menu-hover-link">
                          <DropdownItem header>Actions</DropdownItem>
                          <DropdownItem onClick={() => { props.editCampagne(campagne.id)}}>
                              <i className="dropdown-icon lnr-cross-circle"> </i>
                              <span>Editer</span>
                          </DropdownItem>
                          <DropdownItem onClick={() => { props.deleteCampagne(campagne.id)}}>
                              <i className="dropdown-icon lnr-cross-circle"> </i>
                              <span>Effacer</span>
                          </DropdownItem>
                      </DropdownMenu>
                  </UncontrolledButtonDropdown>
              </div>
          </CardHeader>
          <CardBody>
          <p>
          <b>   Du</b>
          <Badge color="info">{campagne.date_debut}</Badge>
          <b>  au </b>
          <Badge color="info">{campagne.date_fin}</Badge>
          </p>   


          <Button  onClick={() => {
          toggleShowMore(!isShowingMore);
        }}
      > 
            {isShowingMore ? 'Masquer' : 'Voir '} Medias affiliés :  {campagne.affiliate && campagne.affiliate.length ?  campagne.affiliate.length :  0 } 

          </Button>
          {isShowingMore && (
            <Card>
                <Table dark className="mb-0">
        <thead>
          <tr>
            <th>Media</th>
            <th>Options</th>
            <th>Canal</th>
            <th>Site</th>
            <th>Durée diff.</th>
          </tr>
        </thead>
        <tbody>

        {campagne.affiliate && campagne.affiliate.map(function (affilied) {
                          let mediaName = medias.filter(media => media.id === affilied.id_media);
                          let canal =  canaux.items.filter(canal=> canal.id === affilied.canal ) 
//                        let pdvliste = pdvclient.items.filter(item => yFilter.includes(item));
   

                        let res = pdvclient.items.filter(item => affilied.pdvs.includes(String(item.id)));

                          /*
                          var filtered = pdvclient.items.filter(
                            function(e) {
                              return this.indexOf(e) < 0;
                            },
                            affilied.pdvs
                            );*/
                      console.log(affilied);

                    //   let yFilter = affilied.pdvs.items.map(itemY => { return itemY; });
                       //console.log(yFilter);

  //                      let pdvliste = pdvclient.items.filter(item => yFilter.includes(item));

                          console.log('debug')
    //                      console.log(pdvliste)

                          if( mediaName[0]){
                            if (mediaName[0].name.split('.').pop() != "mp4"  ) {

                              return [
                                <tr key={campagne.id + mediaName[0].id}>

                                  <td  >{mediaName[0].name}</td>           
                                  <td><i className="lnr-cross-circle" onClick={() => { props.unAffiliate(affilied.id_slot)} }> </i>Retirer</td>
                                  
                                  <td>{canal[0] ? canal[0].name : "" }</td>           
                                  <td>
                                  
                                  <UncontrolledButtonDropdown>
                                              <DropdownToggle caret className="mb-2 mr-2" color="primary">
                                              Sites  
                                              </DropdownToggle>
                                              <DropdownMenu>
                                              <DropdownItem header>Liste des points de vente</DropdownItem>
                                              {res.map((item) => <DropdownItem>{item.name}</DropdownItem>)}

                                              </DropdownMenu>
                                          </UncontrolledButtonDropdown>

                                  
                                  </td>           
                                  <td>  {affilied.slot_media.duration}</td>           




                          
                                
                                </tr>
  
                              ]


                            }
                            else{
                              return [
                                <tr key={campagne.id + mediaName[0].id}>
                                  <td  >{mediaName[0].name}</td>           
                                  <td><i className="lnr-cross-circle" onClick={() => { props.unAffiliate(affilied.id_slot)} }> </i>Retirer</td>
                                  <td  >{canal[0] ? canal[0].name :  ""  }</td>           
                                  <td>
                                  
                                  <UncontrolledButtonDropdown>
                                              <DropdownToggle caret className="mb-2 mr-2" color="primary">
                                              Sites  
                                              </DropdownToggle>
                                              <DropdownMenu>
                                              <DropdownItem header>Liste des points de vente</DropdownItem>

                                              {res.map((item) => <DropdownItem>{item.name}</DropdownItem>)}

                                              </DropdownMenu>
                                          </UncontrolledButtonDropdown>

                                  
                                  </td>           

                               
                                </tr>
  
                              ]

                            }
          

                          }



                          }
                        )}
                          
        </tbody>
        <Button color="success"> Valider</Button>

<Button color="secondary"  toggle={() => setShow(false)} >Annuler</Button>
      </Table>
            
            
            </Card>
          )}
          </CardBody>
      </Card>
      </div>
  );
};

export default Campagne