import React, {Fragment, Component, useRef, useState,useReducer} from "react";
import {Button, Modal, ModalHeader, ModalBody, ModalFooter} from 'reactstrap';
import { monitoringActions } from '../../../_actions/monitoring.actions';
import { connect } from 'react-redux';
import Hamburger from 'react-hamburgers';
import InfosPlayer from './InfosPlayer';


import Drawer from 'react-motion-drawer';

class MonitoringPlayer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            modal: false,
            openRight:false,
            playerId:false
        };

        this.toggle = this.toggle.bind(this);
    }

    toggle(id) {
        console.log(id)
        this.setState(prevState => ({
            dropdownOpen: !prevState.dropdownOpen
        }));
        this.setState({
            playerId: id
            
        });
        this.setState({openRight: !this.state.openRight, openLeft: false});
    }



    render() {
        const {
            openRight,playerId

        } = this.state;
        return (
            <Fragment>

            <Drawer
                    right
                    className="drawer-content-wrapper p-0"
                    width={800}
                    open={openRight}
                    noTouchOpen={false}
                    noTouchClose={false}
                >{this.state.playerId &&
                    <>
            <InfosPlayer playerId={this.state.playerId}/>
                 
                    </>
                    }          
                    
                </Drawer>
                </Fragment>

        );
    }
}


/*
function mapState(state) {
    const { users, authentication, players } = state;
    const { user } = authentication;
    
    return { user, users, players };
    }
    
    const actionCreators = {
    getInfoPlayer: monitoringActions.getPlayers,
    toggle: () =>  this.setState({
        modal: !this.state.modal
    }),
    }
    
    //const connectedInterfacePage = connect(mapState, actionCreators)(Interface);
    //export default connect(mapState, actionCreators)(Interface);
    const connectedMonitoringPlayer= connect(mapState, actionCreators)(MonitoringPlayer);
    export { connectedMonitoringPlayer as MonitoringPlayer };
*/
    export default MonitoringPlayer;
