import { interfaceConstants } from '../_constants';
import { campagne } from './campagne.reducer';

export function campagnes(state = {}, action) {
  switch (action.type) {
    case interfaceConstants.CAMPAGNES_REQUEST:
      return {
        loading: true
      };
    case interfaceConstants.CAMPAGNES_SUCCESS:
      return {
        campagnes: action.campagnes
      };
    case interfaceConstants.CAMPAGNES_FAILURE:
      return { 
        error: action.error
      };
      case interfaceConstants.ADDCAMPAGNE_REQUEST:
          return {
            ...state, 

          };
      case interfaceConstants.ADDCAMPAGNE_SUCCESS:
          return { 
            ...state,
            campagnes: [
                ...state.campagnes.slice(0, 0),
                action.campagne,
                ...state.campagnes.slice(0),
            ],
        }


      case interfaceConstants.AFFILIATEMEDIA_SUCCESS:
        
        console.log("toto");
         var o = [...state.campagnes];
        const index = o.map(item => item.id).indexOf(action.assigned.id_campagne);
        const campagnes = [...state.campagnes ];
        console.log(campagnes)
        console.log(index)
        campagnes[index].affiliate.splice(0,0, action.assigned);
        return { 
          ...state,
          campagnes:  campagnes 
      }

      case interfaceConstants.UNAFFILIATE_REQUEST:
        return {
          ...state, 
        };

      case interfaceConstants.UNAFFILIATE_SUCCESS:

        const campagneso = [...state.campagnes ];


        const indexo = campagneso.map(item => item.id).indexOf(action.campagne.id_campagne);

        const index_aff = campagneso[indexo].affiliate.map(item => item.id).indexOf(action.campagne.id_campagne);

        campagneso[indexo].affiliate.splice(index_aff,1);


        return {
          campagnes: campagneso

        };
      
      case interfaceConstants.UNAFFILIATE_FAILURE:
        return {};

      case interfaceConstants.ADDCAMAPAGNE_FAILURE:
          return {};

      case interfaceConstants.DELETE_CAMPAGNE_REQUEST:
            // add 'deleting:true' property to user being deleted
            return {
              ...state,
              campagnes: state.campagnes.map(campagne =>
                campagne.id === action.id
                  ? { ...campagne, deleting: true }
                  : campagne
              )
            };
      case interfaceConstants.DELETE_CAMPAGNE_SUCCESS:
            return {
              campagnes: state.campagnes.filter(campagne => campagne.id !== action.id)
            };

    default:
      return state
  }
};
export function enseigne(state = {}, action) {
  switch (action.type) { 
     case interfaceConstants.ENSEIGNE_REQUEST:
      return {
        loading: true
      };
    case interfaceConstants.ENSEIGNE_SUCCESS:
      return {
        items: action.enseigne
      };
    case interfaceConstants.ENSEIGNE_FAILURE:
      return { 
        error: action.error
      };
     
    default:
      return state
  }
}