export const monitoringConstants = {
    PLAYERS_REQUEST : 'PLAYERS_GET_REQUEST',
    PLAYERS_SUCCESS : 'PLAYERS_GET_SUCCESS',
    PLAYERS_FAILURE : 'PLAYERS_GET_FAILURE',

    PLAYERS_SEARCH_REQUEST : 'PLAYERS_SEARCH_REQUEST',
    PLAYERS_SEARCH_SUCCESS : 'PLAYERS_SEARCH_SUCCESS',
    PLAYERS_SEARCH_FAILURE : 'PLAYERS_SEARCH_FAILURE',

    PLAYERS_GET_INFOS_REQUEST : 'PLAYERS__GET_INFOS_REQUEST',
    PLAYERS_GET_INFOS_SUCCESS : 'PLAYERS__GET_INFOS_SUCCESS',
    PLAYERS_GET_INFOS_FAILURE : 'PLAYERS_GET_INFOS_FAILURE',


  
};
