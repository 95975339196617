import { interfaceConstants } from '../_constants';

export function pdvclient(state = {}, action) {
  switch (action.type) { 
     case interfaceConstants.PDVCLIENT_REQUEST:
      return {
        loading: true
      };
    case interfaceConstants.PDVCLIENT_SUCCESS:
      return {
        items: action.pdvclient
      };
    case interfaceConstants.PDVCLIENT_FAILURE:
      return { 
        error: action.error
      };
    default:
      return state
  }
}