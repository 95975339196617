import React, {Component, Fragment} from 'react';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import PageTitleAlt3 from '../../Layout/AppMain/PageTitleAlt3';
import cx from 'classnames';



import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActions,interfaceActions } from '../../_actions';

import {
    Row, Col,
    Alert,
    Button,
    CardHeader,
    Table,
    ButtonGroup,
    Nav,
    NavItem,
    NavLink,
    Popover,
    PopoverBody,
    Progress,
    CardTitle,
    Card,
    Form,
    CardBody,
    DropdownItem, DropdownToggle, DropdownMenu,
    UncontrolledButtonDropdown, CardFooter,
    Container,
    FormGroup,
    Label,
    Input,
    FormText,
    FormFeedback,
    Collapse,
TabContent,
TabPane



} from 'reactstrap';

import PageTitle from '../../Layout/AppMain/PageTitle';

import {
    faAngleUp,
    faAngleDown,
    faQuestionCircle,
    faBusinessTime,
    faCog
} from '@fortawesome/free-solid-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio} from 'availity-reactstrap-validation';

class Settings extends Component {
  
    constructor(props) {
        super(props);


        this.state = {
            visible: true,
            popoverOpen1: false,
            seriesRadial: [76],
            oldPassword:"",
            newPassword1:"",
            newPassword2:"",
            
        }
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }
    componentDidMount() {
      console.log('MOUNBT');
  }
  handleChange(event) {
    const value = event.target.value;
    const keyName  = event.target.name;
    //this.setState({ {evt.target.name } : value});
    this.setState({ [keyName]: event.target.value });


    //const { name, value } = e.target;
 //   this.setState({ [name]: value });
}
  handleSubmit(event) {
     event.preventDefault();
    this.setState({ submitted: true });
    console.log(this.props);
    const { oldPassword, newPassword1, newPassword2,} = this.state;
    if (oldPassword, newPassword1, newPassword2 ) {
        //this.props.addCampagne(name, datefro, dateto, timefrom, timeto, selectOptions);
        this.props.changePassword(oldPassword, newPassword1, newPassword2,);
    }
    //alert('Your favorite flavor is: ' + this.state.value);
   // event.preventDefault();
  }


    render() {
        const breads = [{ name: 'configurations',href:'settings' }];

        const {user,users, enseigne, enseignes } = this.props;

        console.log(user)
        return (
            
            <Fragment>
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                           <div>
                    <div
                        className={cx("app-inner-layout", {
                            'open-mobile-menu': this.state.active,
                        })}
                            
                    >
                      <div className="app-inner-layout__header bg-heavy-rain">
                            <PageTitle
                                heading="Mon compte" 
                                subheading="gestion de votre compte"
                                icon="pe-7s-power icon-gradient bg-mixed-hopes"
                                breads= {breads}
                            />
                        </div>
                        <div class="app-inner-layout__wrapper">
                        <Card className="app-inner-layout__content">

                    <Container fluid>
                                        <TabPane tabId="1">

                                            <div id="accordion" className="accordion-wrapper mb-3">
                                                <Card>
                                                    <CardHeader id="headingOne">
                                                       
                                                            <h3 className="form-heading">
                                                                 Information Sur le compte 
                                                                <p></p>
                                                            </h3>
                                                    </CardHeader>
                                                    <Collapse isOpen={true} data-parent="#accordion"
                                                              id="collapseOne" aria-labelledby="headingOne">
                                                        <CardBody>
                                                            <Row form>
                                                                <Col md={6}>
                                                                    <FormGroup>
                                                                        <Label for="exampleEmail2">Email</Label>
                                                                        <FormText>{user.user.email}</FormText>

                                                                        <Label for="exampleEmail2">Date dernière connexion</Label>
                                                                        <FormText> {user.user.infos.last_connected} </FormText>


                                                                        <Label for="exampleEmail2">IP dernière connexion</Label>
                                                                        <FormText> {user.user.infos.ip} </FormText>
                                                                    </FormGroup>
                                                                </Col>
                                                            </Row>
                                                        </CardBody>
                                                    </Collapse>
                                                </Card>
                                                <Card>
                                                    <CardHeader className="b-radius-0" id="headingTwo">
                                                     
                                                            <h3 className="form-heading">
                                                                Changement mot de passe 
                                                                <p>Veuilez entrer vos informations:</p>
                                                            </h3>
                                                      
                                                    </CardHeader>
                                                    <Collapse  isOpen={true} data-parent="#accordion"
                                                              id="collapseTwo">
                                                        <CardBody>
                                                        <AvForm  onValidSubmit={this.handleSubmit}>

                                                            <FormGroup>
                                                                <Label for="oldPassword">Ancien mot de passe</Label>
                                                                <AvField type="password" name="oldPassword" id="oldPassword" onChange={this.handleChange} required />
                                                               <FormText></FormText>
                                                            
                                                               <Label for="newPassword1">Nouveau mot de passe</Label>
                                                                <AvField type="password" name="newPassword1" id="newPassword1"  onChange={this.handleChange} required/>
                                                               <FormText></FormText>
                                                            
                                                               <Label for="newPassword2">Confirmation nouveau mot de passe</Label>
                                                                <AvField type="password" name="newPassword2" id="newPassword2" onChange={this.handleChange} required/>
                                                               <FormText></FormText>
                                                            
                                                            </FormGroup>
                                                            <button  className="btn btn-primary" >Changer mot de passe</button>
                                                    </AvForm>
                                                        </CardBody>
                                                      
                                                    </Collapse>
                                                   

                                                </Card>
                                                <Card>{/*  
                                                    <CardHeader id="headingThree">
                                                       
                                                            <h3 className="form-heading">
                                                                Double authentication
                                                                <p></p>
                                                            </h3>
                                                    </CardHeader>
                                                    <Collapse  data-parent="#accordion"
                                                              id="collapseThree">
                                                        <CardBody>
                                                            <FormGroup>
                                                                <Label for="exampleEmail5">Input without validation</Label>
                                                                <Input/>
                                                                <FormFeedback>You will not be able to see this</FormFeedback>
                                                            </FormGroup->
                                                        </CardBody>
                                                    </Collapse>
                                                    */}
                                                </Card>
                                            </div>

                                            
                                        </TabPane>
                       
                                
                                
                    
                    </Container>
                    </Card>
                    </div>
                    </div>

                    </div>

                </ReactCSSTransitionGroup>
            </Fragment>
        )
    }

_onAction(e) {
  console.log('user did something', e)
}

_onActive(e) {
  console.log('user is active', e)
  console.log('time remaining', this.idleTimer.getRemainingTime())
}

_onIdle(e) {
  console.log('user is idle', e)
  console.log('last active', this.idleTimer.getLastActiveTime())
}
}


function mapState(state) {
const { users, authentication, enseignes } = state;
const { user } = authentication;
return { user, users, enseignes };
}

const actionCreators = {
changePassword: userActions.changePassword,
}

//const connectedInterfacePage = connect(mapState, actionCreators)(Interface);
//export default connect(mapState, actionCreators)(Interface);
const connectedInterface = connect(mapState, actionCreators)(Settings);
export { connectedInterface as Settings };