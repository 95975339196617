import { interfaceConstants } from '../_constants';

export function canaux(state = {}, action) {
  switch (action.type) { 
     case interfaceConstants.CANAUX_REQUEST:
      return {
        loading: true
      };
    case interfaceConstants.CANAUX_SUCCESS:

    
      return {
        items: action.canaux
      };
    case interfaceConstants.CANAUX_FAILURE:
      return { 
        error: action.error
      };
    default:
      return state
  }
}