export const interfaceConstants = {
    ENSEIGNES_REQUEST : 'ENSEIGNES_GET_ALL_REQUEST',
    ENSEIGNES_SUCCESS : 'ENSEIGNES_GET_ALL_SUCCESS',
    ENSEIGNES_FAILURE : 'ENSEIGNES_GET_ALL_FAILURE',

    ENSEIGNE_REQUEST : 'ENSEIGNE_GET_REQUEST',
    ENSEIGNE_SUCCESS : 'ENSEIGNE_GET_SUCCESS',
    ENSEIGNE_FAILURE : 'ENSEIGNE_GET_FAILURE',

    PDV_REQUEST : 'PDV_GET_REQUEST',
    PDV_SUCCESS : 'PDV_GET_SUCCESS',
    PDV_FAILURE : 'PDV_GET_FAILURE',

    CAMPAGNES_REQUEST : 'CAMPAGNES_GET_ALL_REQUEST',
    CAMPAGNES_SUCCESS : 'CAMPAGNES_GET_ALL_SUCCESS',
    CAMPAGNES_FAILURE : 'CAMPAGNES_GET_ALL_FAILURE',

    CAMPAGNE_REQUEST : 'CAMPAGNE_GET_REQUEST',    
    CAMPAGNE_EDIT : 'CAMPAGNE_GET_EDIT',
    CAMPAGNE_SUCCESS : 'CAMPAGNE_GET_SUCCESS',
    CAMPAGNE_FAILURE : 'CAMPAGNE_GET_FAILURE',

    ADDCAMPAGNE_REQUEST : 'CAMPAGNE_POST_REQUEST',
    ADDCAMPAGNE_SUCCESS : 'CAMPAGNE_POST_SUCCESS',
    ADDCAMPAGNE_FAILURE : 'CAMPAGNE_POST_FAILURE',

    ADDMEDIA_REQUEST : 'MEDIA_POST_REQUEST',
    ADDMEDIA_SUCCESS : 'MEDIA _POST_REQUEST',
    ADDMEDIA_FAILURE : 'MEDIA_POST_REQUEST',

    SECTEURS_REQUEST : 'SECTEURS_GET_REQUEST',
    SECTEURS_SUCCESS : 'SECTEURS_GET_SUCCESS',
    SECTEURS_FAILURE : 'SECTEURS_GET_FAILURE',

    MEDIAS_REQUEST : 'MEDIAS_GET_REQUEST',
    MEDIAS_SUCCESS : 'MEDIAS_GET_SUCCESS',
    MEDIAS_FAILURE : 'MEDIAS_GET_FAILURE',

    AFFILIATEMEDIA_REQUEST : 'AFFILIATE_POST_REQUEST',
    AFFILIATEMEDIA_SUCCESS : 'AFFILIATE_POST_SUCCESS',
    AFFILIATEMEDIA_FAILURE : 'AFFILIATE_POST_FAILURE' ,


    DELETE_CAMPAGNE_REQUEST: 'DELETE_CAMPAGNE_REQUEST',
    DELETE_CAMPAGNE_SUCCESS: 'DELETE_CAMPAGNE_SUCCESS',
    DELETE_CAMPAGNE_FAILURE: 'DELETE_CAMPAGNE_FAILURE',    


    DELETE_MEDIA_REQUEST: 'DELETE_MEDIA_REQUEST',
    DELETE_MEDIA_SUCCESS: 'DELETE_MEDIA_SUCCESS',
    DELETE_MEDIA_FAILURE: 'DELETE_MEDIA_FAILURE',

    EVENTS_REQUEST : 'EVENTS_GET_REQUEST',
    EVENTS_SUCCESS : 'EVENTS_GET_SUCCESS',
    EVENTS_FAILURE : 'EVENTS_GET_FAILURE',

    UNAFFILIATE_REQUEST: 'UNAFFILIATE_REQUEST',
    UNAFFILIATE_SUCCESS: 'UNAFFILIATE_SUCCESS',
    UNAFFILIATE_FAILURE: 'UNAFFILIATE_FAILURE',

    UPDATEPLAYLIST_REQUEST : 'UPDATEPLAYLIST_POST_REQUEST',
    UPDATEPLAYLIST_SUCCESS : 'UPDATEPLAYLIST_POST_SUCCESS',
    UPDATEPLAYLIST_FAILURE : 'UPDATEPLAYLIST_POST_FAILURE',


    CLIENTS_REQUEST : 'CLIENTS_GET_ALL_REQUEST',
    CLIENTS_SUCCESS : 'CLIENTS_GET_ALL_SUCCESS',
    CLIENTS_FAILURE : 'CLIENTS_GET_ALL_FAILURE',


    CANAUX_REQUEST : 'CANAUX_GET_ALL_REQUEST',
    CANAUX_SUCCESS : 'CANAUX_GET_ALL_SUCCESS',
    CANAUX_FAILURE : 'CANAUX_GET_ALL_FAILURE',



    PDVCLIENT_REQUEST : 'PDVCLIENT_GET_ALL_REQUEST',
    PDVCLIENT_SUCCESS : 'PDVCLIENT_GET_ALL_SUCCESS',
    PDVCLIENT_FAILURE : 'PDVCLIENT_GET_ALL_FAILURE',
};
