import React, {Fragment, Component, useRef} from "react";
import { useDrag } from 'react-dnd'
import ItemTypes from './ItemTypes'
import scrolling from  './Libs/scrolling';

const style = {
  border: '1px dashed gray',
  backgroundColor: 'white',
  padding: '0.5rem 1rem',
  marginRight: '1.5rem',
  marginBottom: '1.5rem',
  cursor: 'move',
}
const Media = ({ id, children }) => {
  //const ref = useRef(null);
  const [{ isDragging }, drag] = useDrag({
    item: { type: "card", id },
    end: (item, monitor) => {
      scrolling.removeEventListenerForSidebar()

      const dropResult = monitor.getDropResult()
      if (item && dropResult) {
       // alert(`You dropped ${item.name} into ${dropResult.name}!`)
      }
    },
    begin : () =>{
      console.log("beginDrag")
      scrolling.addEventListenerForSidebar("nav-campagne");

    },
    collect: monitor => ({
      isDragging: monitor.isDragging()
    })
  });
  const opacity = isDragging ? 0 : 1;
  //drag(ref);
  return (
    <td ref={drag} style={{ opacity }}>
        <i className="header-icon lnr-move icon-gradient bg-plum-plate"> </i>
    </td>
  );
};
export default Media
