import React, {Component, Fragment} from 'react';
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';

import PageTitleAlt3 from '../../Layout/AppMain/PageTitleAlt3';
import cx from 'classnames';



import classnames from 'classnames';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { userActions,interfaceActions } from '../../_actions';

import {
    Row, Col,
    Alert,
    Button,
    CardHeader,
    Table,
    ButtonGroup,
    Nav,
    NavItem,
    NavLink,
    Popover,
    PopoverBody,
    Progress,
    CardTitle,
    Card,
    CardBody,
    DropdownItem, DropdownToggle, DropdownMenu,
    UncontrolledButtonDropdown, CardFooter,
    Container
} from 'reactstrap';

import PageTitle from '../../Layout/AppMain/PageTitle';

import {
    faAngleUp,
    faAngleDown,
    faQuestionCircle,
    faBusinessTime,
    faCog
} from '@fortawesome/free-solid-svg-icons';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import GridTables from './GridTables';
import { monitoringActions } from '../../_actions/monitoring.actions';

class Monitoring extends Component {
  
    constructor(props) {
        super(props);

        this.togglePop1 = this.togglePop1.bind(this);

        this.state = {
            visible: true,
            popoverOpen1: false,
            seriesRadial: [76],
            page: 1,
            sizePerPage: 25,
            currentPage: 1,
            openRight: false,

            
        }
        this.onDismiss = this.onDismiss.bind(this);
    }
    componentDidMount() {
      console.log('MOUNBT');

      this.props.getPlayers( this.state.page,this.state.sizePerPage)

  }
    togglePop1() {
        this.setState({
            popoverOpen1: !this.state.popoverOpen1
        });
    }

    onDismiss() {
        this.setState({visible: false});
    }

     handleTableChange = (type, { page, sizePerPage,term, }) => {
        console.log("handleTableChange")
        console.log(type)
        console.log(page - 1)

        const currentIndex = (page - 1) * sizePerPage;
        
        if (type == "pagination"){
            this.setState({
                page,
                sizePerPage

            });
            this.props.getPlayers(page,this.state.sizePerPage)

        }
         
        if (type == "filter"){

            this.props.searchPlayers(term,page,sizePerPage,)



        }
        //grab page xxx 
        console.log(currentIndex)
      }

    render() {
        const { players ,sizePerPage, page,openRight,openLeft} = this.props;
        const breads = [{ name: 'monitoring',href:'monitoring' }];

        return (
            
            <Fragment>
                <ReactCSSTransitionGroup
                    component="div"
                    transitionName="TabsAnimation"
                    transitionAppear={true}
                    transitionAppearTimeout={0}
                    transitionEnter={false}
                    transitionLeave={false}>
                           <div>
                    <div
                        className={cx("app-inner-layout", {
                            'open-mobile-menu': this.state.active,
                        })}
                            
                    >
                      <div className="app-inner-layout__header bg-heavy-rain">
                            <PageTitle
                                heading="Monitoring" 
                                subheading="gestion des players"
                                icon="pe-7s-power icon-gradient bg-mixed-hopes"
                                breads={breads}
                            />
                        </div>
                        <div class="app-inner-layout__wrapper">
                        <Card className="app-inner-layout__content">

                    <Container fluid>
             
                    {players.items &&
                                <>
                    <div>
{players.items.data.id}
</div>

                            <GridTables data={players.items} page={ players.items.current_page }
                    sizePerPage={  players.items.per_page }
                    totalSize={ players.items.total }
                    onTableChange={this.handleTableChange}
                    >
                           
                            
                           </GridTables>
                                </>
                                }     
                    
                            
                            
                    
                    </Container>
                    </Card>
                    </div>
                    </div>

                    </div>

                </ReactCSSTransitionGroup>
            </Fragment>
        )
    }

_onAction(e) {
  console.log('user did something', e)
}

_onActive(e) {
  console.log('user is active', e)
  console.log('time remaining', this.idleTimer.getRemainingTime())
}

_onIdle(e) {
  console.log('user is idle', e)
  console.log('last active', this.idleTimer.getLastActiveTime())
}
}


function mapState(state) {
const { users, authentication, players } = state;
const { user } = authentication;
return { user, users, players };
}

const actionCreators = {
getPlayers: monitoringActions.getPlayers,
searchPlayers: monitoringActions.searchPlayers


}

//const connectedInterfacePage = connect(mapState, actionCreators)(Interface);
//export default connect(mapState, actionCreators)(Interface);
const connectedMonitoring = connect(mapState, actionCreators)(Monitoring);
export { connectedMonitoring as Monitoring };