import { userConstants } from '../_constants';
import { userService } from '../_services';
import { alertActions } from './';
import { history } from '../_helpers';
import { store } from 'react-notifications-component';

export const userActions = {
    login,
    logout,
    getAll,
    delete: _delete,
    changePassword,

};

function login(username, password) {
    return dispatch => {
        dispatch(request({ username }));

        userService.login(username, password)
            .then(
                user => { 
                    dispatch(success(user));
                    history.push('/dashboard/interface');
                },
                error => {
                    dispatch(failure(error.toString()));
                    store.addNotification({
                        title: "Oops une erreur est survenue !",
                        message: error.toString(),
                        type: "danger",
                        insert: "top",
                        container: "top-center",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                          duration: 10000,
                          onScreen: true
                        }
                      });
                    
//                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.LOGIN_REQUEST, user } }
    function success(user) { return { type: userConstants.LOGIN_SUCCESS, user } }
    function failure(error) { return { type: userConstants.LOGIN_FAILURE, error } }
}

function logout() {
    userService.logout();
    return { type: userConstants.LOGOUT };
}
function changePassword(oldPassword, newPassword1, newPassword2) {
    return dispatch => {
        dispatch(request({ oldPassword, newPassword1, newPassword2 }));

        userService.changePassword(oldPassword, newPassword1, newPassword2)
            .then(
                campagne => { 
//                    dispatch(success(campagne));

                    store.addNotification({
                        title: "[Notification utilisateur]",
                        message: "Mot de pass changé avec succès",
                        type: "success",
                        insert: "top",
                        container: "top-center",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                          duration: 10000,
                          onScreen: true
                        }
                      });

                },
                error => {
                    dispatch(failure(error.toString()));
                    store.addNotification({
                        title: "Oops une erreur est survenue !",
                        message: error.toString(),
                        type: "danger",
                        insert: "top",
                        container: "top-center",
                        animationIn: ["animated", "fadeIn"],
                        animationOut: ["animated", "fadeOut"],
                        dismiss: {
                          duration: 10000,
                          onScreen: true
                        }
                      });
                    
                }
            );
    };

    function request() { return { type: userConstants.CHANGE_PASSWORD_REQUEST } }
    function success(campagne) { return { type: userConstants.CHANGE_PASSWORD_SUCCESS, campagne } }
    function failure(error) { return { type: userConstants.CHANGE_PASSWORD_FAILURE, error } }

}
/*
function register(user) {
    return dispatch => {
        dispatch(request(user));

        userService.register(user)
            .then(
                user => { 
                    dispatch(success());
                    history.push('/login');
                    dispatch(alertActions.success('Registration successful'));
                },
                error => {
                    dispatch(failure(error.toString()));
                    dispatch(alertActions.error(error.toString()));
                }
            );
    };

    function request(user) { return { type: userConstants.REGISTER_REQUEST, user } }
    function success(user) { return { type: userConstants.REGISTER_SUCCESS, user } }
    function failure(error) { return { type: userConstants.REGISTER_FAILURE, error } }
}
*/
function getAll() {
    return dispatch => {
        dispatch(request());

        userService.getAll()
            .then(
                users => dispatch(success(users)),
                error => dispatch(failure(error.toString()))
            );
    };

    function request() { return { type: userConstants.GETALL_REQUEST } }
    function success(users) { return { type: userConstants.GETALL_SUCCESS, users } }
    function failure(error) { return { type: userConstants.GETALL_FAILURE, error } }
}

// prefixed function name with underscore because delete is a reserved word in javascript
function _delete(id) {
    return dispatch => {
        dispatch(request(id));

        userService.delete(id)
            .then(
                user => dispatch(success(id)),
                error => dispatch(failure(id, error.toString()))
            );
    };

    function request(id) { return { type: userConstants.DELETE_REQUEST, id } }
    function success(id) { return { type: userConstants.DELETE_SUCCESS, id } }
    function failure(id, error) { return { type: userConstants.DELETE_FAILURE, id, error } }
}