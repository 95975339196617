export function authHeader(extraHeader) {
    // return authorization header with jwt token
    let user = JSON.parse(localStorage.getItem('user'));

    if (user && user.token) {
        if (extraHeader){
            return { 'Authorization': 'Bearer ' + user.token ,'Accept': 'application/json', 'Content-Type': extraHeader};      

        }else{
            return { 'Authorization': 'Bearer ' + user.token };      
        }     
    } else {
        return {};
    }
}