import React, {Component, Fragment} from 'react';
import Chart from 'react-apexcharts'
import ReactCSSTransitionGroup from 'react-transition-group/CSSTransitionGroup';
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom";
import {Progress} from 'react-sweet-progress';
import CountUp from 'react-countup';
import DateTimePicker from 'react-datetime-picker';
import TimePicker from 'react-time-picker';

import {
    Col, Row, Button,
    Card, Nav, NavLink, NavItem,
    DropdownMenu, DropdownItem,ListGroup, ListGroupItem,
    CardBody,CustomInput, InputGroup, InputGroupAddon, Input,
    Table, UncontrolledButtonDropdown, DropdownToggle,CardTitle,
    ButtonGroup,Form, FormGroup, Label,FormText, Container
} from 'reactstrap';


import {AvForm, AvField, AvGroup, AvInput, AvFeedback, AvRadioGroup, AvRadio} from 'availity-reactstrap-validation';

import Hamburger from 'react-hamburgers';
import cx from 'classnames';
import { connect } from 'react-redux';

import { interfaceActions } from '../../../_actions';

export default class FormCampagne extends Component {

    constructor(props) {
        super(props);
        const date = new Date();

        this.state = {
            submitted: false,
            name : "",
            datefrom: date,
            dateto: date,
            timefrom:"",
            timeto: "",
            pdvs :[],
            selectOptions: [],
            selectOptionsIds: []

        };

        this.handleChangeForm = this.handleChangeForm.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleSubmit = this.handleSubmit.bind(this);

    }

    handleChange(event) {
        let target = event.target
		let name = target.name
        //    let value = Array.from(target.selectedOptions, option => option.value);
         //   this.setState({
           // [name]: value
            //});
            console.log(event.target.selectedOptions);
            let value = Array.from(event.target.selectedOptions, option => option.value );
            let id = Array.from(event.target.selectedOptions, option => option.id );
            this.setState({selectOptions: value});
            this.setState({selectOptionsIds: id});

            console.log(value);
      }
    
      handleChangeForm(event) {
        const value = event.target.value;
        const keyName  = event.target.name;
        //this.setState({ {evt.target.name } : value});
        this.setState({ [keyName]: event.target.value });

    
        //const { name, value } = e.target;
     //   this.setState({ [name]: value });
    }
      handleSubmit(event) {
         event.preventDefault();

        this.setState({ submitted: true });
        console.log(this.props);
        const { name, datefrom, dateto, timefrom, timeto, selectOptionsIds} = this.state;
        if (name && datefrom && dateto ) {
            console.log(datefrom);
            //this.props.addCampagne(name, datefrom, dateto, timefrom, timeto, selectOptions);
            this.props.form(name, datefrom, dateto, timefrom, timeto, selectOptionsIds,this.props.enseigne_id);
        }
        //alert('Your favorite flavor is: ' + this.state.value);
       // event.preventDefault();
      }
    onChangeDateFrom = datefrom => this.setState({ datefrom })
    onChangeDateTo = dateto => this.setState({ dateto })
    onChangeTimeFrom = timefrom => this.setState({ timefrom })
    onChangeTimeTo = timeto => this.setState({ timeto })

     componentDidMount() {
    
    }
    render() {

        return (
            <Card className="main-card mb-3">
            <CardBody>

                <CardTitle>Ajout de campagne</CardTitle>
                    <AvForm  onValidSubmit={this.handleSubmit}>

                    <AvGroup row>
                        <Label for="exampleEmail" sm={4}>Nom de la séquence</Label>
                        <Col sm={10}>
                            <AvField type="text" name="name" id="name" onChange={this.handleChangeForm} required />
                            <AvFeedback>Champ Obligatoire</AvFeedback>

                        </Col>
                    </AvGroup>

                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="exampleEmail11">Date de début</Label>
                                 <DateTimePicker
                                    onChange={this.onChangeDateFrom}
                                    value={this.state.datefrom}
                                    format={"y-MM-dd H:mm:ss"}

                                 />
                    </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="examplePassword11">Date de fin</Label>
                                <DateTimePicker
                                    onChange={this.onChangeDateTo}
                                    value={this.state.dateto}
                                    format={"y-MM-dd H:mm:ss"}
                                    
                                 />
                            </FormGroup>
                        </Col>
                    </Row>
                    <Row form>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="exampleEmail11">Heure de début</Label>
                                <TimePicker 
                                    onChange={this.onChangeTimeFrom}
                                    value={this.state.timefrom}
                                 />
                            </FormGroup>
                        </Col>
                        <Col md={6}>
                            <FormGroup>
                                <Label for="examplePassword11">Heure de fin</Label>
                                <TimePicker
                                    onChange={this.onChangeTimeTo}
                                    value={this.state.timeto}
                               
                                 />
                            </FormGroup>
                        </Col>
                    </Row>
               {/** 
                    <FormGroup row>
                        <Label for="exampleText" sm={2}>PDV</Label>
                        <Col sm={10}>
                            <Input type="select" name="text" id="pdvs"  multiple onChange={this.handleChange} value={this.state.selectOptions.value}> 
                            {this.props.pdvs.items && 
                                    this.props.pdvs.items.map((item) => <option id={item.id} key={item.id}>{item.name}</option>)};
            
                            </Input>
                        </Col>
                        {this.state.selectOptions.join(', ')}

                    </FormGroup>
                                     **/}

                    <FormGroup  row>
                    <input type='hidden' value={ this.props.enseigne_id } />

                        <Col sm={{size: 10, offset: 2}}>
                        <Button className="">Ajouter la campagne</Button>

                        </Col>
                    </FormGroup>
                </AvForm>
                </CardBody>
                </Card>
        );
    }
}


function mapState(state) {
    const { users, authentication, enseignes,enseigne } = state;
    const { user } = authentication;
    
    return { user, users,enseignes,enseigne  };
    }
    
    const actionCreators = {
        addCampagne: interfaceActions.addCampagne,
        titi: interfaceActions.getEnseignes,
    }
    
    //const connectedInterfacePage = connect(mapState, actionCreators)(Interface);
    //export default connect(mapState, actionCreators)(Interface);
    const connectedFormCampagne = connect(mapState, actionCreators)(FormCampagne);
    export { connectedFormCampagne as FormCampagne };


    